<template>
  <li class="blog-list">
    <router-link :to="post.url">
      <div class="info-text">
        <span>
          {{ post.title }}
        </span>
        <div class="line">
          <div class="tag">{{ post.tag }}</div>
          <div class="date">
            <img src="../assets/images/icons/calendar.svg" alt="Calendário">
            <span>{{ post.date }}</span>
          </div>
        </div>
      </div>
      <div class="image">
        <img :src="post.urlCover" :alt="post.tag">
      </div>
    </router-link>
  </li>
</template>

<script>
export default {
  name: "LastBlogItem",
  props: {
    post: {
      id: Number,
      urlCover: String,
      title: String,
      date: String,
      tag: String,
      url: String,
    }
  },
}
</script>

<style scoped lang="scss">
.blog-list {
  &:nth-child(2) {
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    padding: 15px 0px;

    @media (min-width: 768px) {
      padding: 30px 0px;
    }

  }

  a {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-decoration: none;

    &:hover {
      .info-text {
        > span {
          text-decoration: underline;
        }
      }
    }

    .info-text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: start;

      > span {
        font-style: normal;
        text-decoration: none;
        font-weight: normal;
        font-size: 18px;
        line-height: 26px;
        color: #333333;
      }

      .line {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        margin-top: 10px;

        @media (min-width: 768px) {
          margin-top: 0px;
        }

        .tag {
          width: 110px;
          height: 33px;
          background: #333333;
          font-weight: normal;
          font-size: 15px;
          line-height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #FFFFFF;
        }

        .date {
          margin-left: 22px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 87px;
          align-items: center;
          color: #333333;

          img {
            margin-right: 6px;
          }
        }
      }
    }

    .image {
      display: none;
      @media (min-width: 768px) {
        display: block;
      }

      img {
        width: 100%;
      }
    }
  }
}
</style>
