<template>
  <footer class="footer page-footer bg-dark">
    <div class="container h-100">
      <div class="row h-100">
        <div class="col-12 col-lg-9 h-100 d-flex flex-column justify-content-between">
          <div class="row r-top">
            <div class="col">
              <img src="../../assets/logo.svg" alt="MyStand.com.br">
            </div>
          </div>
          <div class="info-texts">
            <span>
              Cel.: 11 99338-8152 | Email: geral@mystand.pt
            </span>
            <span>
              Av. Paulista, Nº28, CEP: 01200-024 - São Paulo
            </span>
          </div>
          <div class="social">
            <SocialButtons/>
          </div>
          <div v-if="windowWidth > 768" class="copy">
            <span>Copyright © 2020 MyStand - Todos os direitos reservados</span>
          </div>
        </div>
        <div class="col-12 col-lg-3 right-content">
          <h4>Newsletter</h4>
          <form action="">
            <div class="form-group">
              <input type="email" placeholder="Your email address" class="form-control" name="email">
              <button type="submit">
                <img src="../../assets/images/icons/send.svg" alt="Send">
              </button>
            </div>
            <span class="small">Ao enviar este formulário está nos dando autorização para utilização dos seus dados
              pessoais e e concordando com a nossa Política Privacidade.
            </span>
          </form>
          <div v-if="windowWidth < 768" class="copy">
            <span>Copyright © 2020 My stand - All rights reserved</span>
          </div>
          <span class="link-owner">
            Feito com <i class="fa fa-heart"></i> por <a href="https://webhouse.pt">Webhouse.pt</a>
          </span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import SocialButtons from "@/components/SocialButtons";

export default {
  name: "Footer",
  components: {SocialButtons},
  data() {
    return {
      windowWidth: window.outerWidth,
    }
  }
}
</script>

<style scoped lang="scss">
.footer {
  padding-bottom: 30px;
  padding-top: 30px;

  @media (min-width: 768px) {
    padding-top: 57px;
    height: 300px;
  }

  .r-top {
    img {
      height: 35px;
      margin-bottom: 20px;

      @media (min-width: 678px) {
        margin-bottom: 0px;
      }
    }
  }

  .info-texts {
    span {
      display: block;
      color: #FFFFFF;
      font-size: 15px;
      line-height: 30px;
    }
  }

  .social {
    margin-top: 40px;

    @media (min-width: 678px) {
      margin-top: 0px;
    }
  }

  .copy {
    span {
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      color: #D9DBE1;
    }
  }

  .right-content {
    position: relative;
    display: flex;
    flex-direction: column;

    h4 {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: #FFFFFF;
      margin-top: 40px;

      @media (min-width: 678px) {
        margin-top: 60px;
      }
    }

    .form-group {
      position: relative;

      .form-control {
        border: none;
        background-color: #313134;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: #D9DBE1;
        float: right;
        height: 50px;
      }

      button {
        top: 0px;
        right: 0px;
        background: none;
        width: 50px;
        height: 50px;
        border: none;
        position: absolute;
      }
    }

    span {
      margin-top: auto;
      font-weight: 300;
      font-size: 14px;
      line-height: 19px;
      color: #FFFFFF;

      &.small {
        font-weight: 400;
        font-size: 8px;
        line-height: 10px;
        margin-bottom: 10px;
        color: #888888;
        display: inline-block;
      }

      &.link-owner {
        height: 18px;
        display: flex;
        align-items: center;
        margin-top: 10px;

        @media (min-width: 678px) {
          margin-top: 0px;
        }

        .fa {
          color: red;
          margin: 0px 5px;
        }

        a {
          margin: 0px 5px;
          text-decoration: none;
          color: #FFF;

          &:hover {
            color: #DCA66A;
          }
        }
      }
    }
  }
}
</style>
