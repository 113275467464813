<template>
  <div>
    <modal width="350" height="auto" :adaptive="true" class="wpp-modal" name="whatsapp-modal">
      <div class="popup-whatsapp">
        <button class="btn btn-link close-modal" @click="closeModalWpp">
          <img src="../../assets/images/icons/close-white.svg" alt="X">
        </button>
        <form action="">
          <input type="text" name="whatsapp_name" id="whatsapp_name" class="form-control" placeholder="Nome">
          <input type="tel" name="whatsapp_phone" id="whatsapp_phone" class="form-control"
                 placeholder="Nº de celular">
          <input type="email" name="whatsapp_mail" id="whatsapp_mail" class="form-control" placeholder="E-mail">
          <button class="btn btn-primary" @click="goToWhatsApp" type="button">Chamar no WhatsApp</button>
          <span class="small">Ao enviar este formulário está nos dando autorização para utilização dos seus dados
            pessoais e
            concordando
            com a nossa Política Privacidade.
          </span>
        </form>
      </div>
    </modal>
    <modal width="350" height="auto" :adaptive="true" class="call-modal" name="popup-modal">
      <div class="popup-call">
        <button class="btn btn-link close-modal" @click="closeModalCall">
          <img src="../../assets/images/icons/close-white.svg" alt="X">
        </button>
        <form action="">
          <input type="text" name="we_call_name" id="we_call_name" class="form-control" placeholder="Nome">
          <input type="tel" name="we_call_phone" id="we_call_phone" class="form-control" placeholder="Nº de celular">
          <button class="btn btn-primary" type="submit">Quero ser contatado</button>
          <p>Serviço disponível apenas das 10:00 às 19:30 de segunda a sábado. Caso o contato seja realizado fora deste
            horário irá ser contatado mais tarde</p>
          <span class="small">Ao enviar este formulário está nos dando autorização para utilização dos seus dados
            pessoais e
            concordando
            com a nossa Política Privacidade.
          </span>
        </form>
      </div>
    </modal>
    <nav class="navbar navbar-expand-lg fixed-top bg-dark page-navbar">
      <div class="container">
        <router-link class="navbar-brand" to="/">
          <img src="../../assets/logo.svg" alt="MyStand.com.br">
        </router-link>
        <div v-if="windowWidth < 768" class="ms-auto whatsapp-box">
          <button class="btn btn-primary" ref="toggle-wpp-popup" @click="showWppPopup"
                  type="button">
            <img src="../../assets/images/icons/whatsapp-gold.svg" alt="whatsapp-icon">
          </button>
        </div>
        <button ref="toggleMenu" @click="menuOpened = !menuOpened" class="navbar-toggler" type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <img v-if="menuOpened" src="../../assets/images/icons/close-white.svg" alt="Icon">
          <img v-else src="../../assets/images/icons/navbar-toggler-icon.svg" alt="Icon">
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <li @click="closeMenu" class="nav-item">
              <router-link :to="{ name: 'vehicles'}" class="nav-link">Veículos</router-link>
            </li>
            <li @click="closeMenu" class="nav-item">
              <router-link :to="{ name: 'about'}" class="nav-link">Sobre nós</router-link>
            </li>
            <li @click="closeMenu" class="nav-item">
              <router-link :to="{ name: 'services'}" class="nav-link">Serviços</router-link>
            </li>
            <li @click="closeMenu" class="nav-item">
              <router-link :to="{ name: 'blog'}" class="nav-link">Blog</router-link>
            </li>
            <li @click="closeMenu" class="nav-item">
              <router-link :to="{ name: 'contacts'}" class="nav-link">Contatos</router-link>
            </li>
          </ul>
          <div v-if="windowWidth > 768" id="whatsapp-box" class="whatsapp-box">
            <button class="btn btn-primary btn-custom" @click="showWppPopup"
                    type="button">
              <img src="../../assets/images/icons/whatsapp-gold.svg" alt="whatsapp-icon">
            </button>
          </div>
          <div id="we-call-box" class="we-call-box">
            <button class="btn btn-primary btn-custom" @click="togglePopup"
                    type="button">
              <img src="../../assets/images/icons/phone.svg" alt="phone-icon">
              Nós Ligamos
            </button>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      windowWidth: window.outerWidth,
      showPopup: false,
      menuOpened: false,
    }
  },
  methods: {
    togglePopup() {
      this.$modal.show('popup-modal')
      if (this.windowWidth < 768) {
        this.$refs.toggleMenu.click()
      }
    },
    onClose() {
      this.showPopup = false
    },
    showWppPopup() {
      this.$modal.show('whatsapp-modal')
    },
    closeMenu() {
      this.$refs.toggleMenu.click()
    },
    closeModalWpp() {
      this.$modal.hide('whatsapp-modal')
    },
    closeModalCall() {
      this.$modal.hide('popup-modal')
    },
    goToWhatsApp() {
      window.open('https://wa.me/351229370729', '_blank');
    }
  }
}
</script>

<style scoped lang="scss">
.popup-whatsapp, .popup-call {
  position: relative;

  .close-modal {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    padding: 0;
    margin: 0;

    img {
      height: 15px;
    }
  }
}

.navbar-toggler {
  width: 40px;
}

.page-navbar {
  height: 88px;

  @media (max-width: 768px) {
    > .container {
      background-color: #1B1B1E;
    }

    .navbar-nav {
      margin: 20px 0px;
    }
  }

  .nav-item {
    @media (min-width: 768px) {
      margin-left: 26px;
    }

    .nav-link {
      padding-right: 0px;
      padding-left: 0px;
      color: #FFFFFF;
      font-weight: 400;
      line-height: 40px;
      font-size: 20px;

      @media (min-width: 768px) {
        font-size: 16px;
        line-height: 23px;
      }

      &:hover {
        color: #DCA66A;
      }

      &.router-link-active {
        color: #DCA66A;
      }
    }
  }

  .we-call-box {
    position: relative;

    .btn-primary {
      font-weight: 500;
      height: 42px;
      width: 169px;
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 18px;

      @media (min-width: 768px) {
        margin-bottom: 0px;
        font-size: 16px;
        margin-left: 25px;
        margin-top: 0px;
      }

      img {
        margin-right: 5px;
        //filter: invert(100);
      }
    }
  }

  .whatsapp-box {
    .btn-primary {
      font-weight: 500;
      height: 42px;
      background-color: transparent;
      border: none;
      width: 42px;
      margin-left: 25px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 40px;
        display: flex;

        @media (min-width: 678px) {
          height: 35px;
        }
      }
    }
  }

}

.popup-call {
  width: 350px;
  background: #1B1B1E;
  border-radius: 3px;
  padding: 40px 20px;

  .form-control {
    background: #FFFFFF;
    border: 1px solid #E7E7E7;
    box-sizing: border-box;
    border-radius: 3px;
    margin-bottom: 13px;
    height: 43px;
  }

  .btn-primary {
    width: 100%;
    margin-top: 3px;
    font-weight: 400;
    font-size: 17px;
    line-height: 23.15px;
    height: 42px;
    border-radius: 3px;
  }

  p {
    font-weight: 400;
    font-size: 13px;
    line-height: 16.5px;
    color: #FFFFFF;
    margin-top: 20px;
  }

  .small {
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    color: #888888;
    display: inline-block;
    text-align: center;
  }
}

.popup-whatsapp {
  width: 350px;
  background: #1B1B1E;
  border-radius: 3px;
  padding: 40px 20px;

  .close {
    position: absolute;
    top: 0;
    right: 0;
    color: #FFFFFF;
    cursor: pointer;
  }

  .form-control {
    background: #FFFFFF;
    border: 1px solid #E7E7E7;
    box-sizing: border-box;
    border-radius: 3px;
    margin-bottom: 13px;
    height: 43px;
  }

  .btn-primary {
    width: 100%;
    margin-top: 3px;
    font-weight: 400;
    font-size: 17px;
    line-height: 23.15px;
    height: 42px;
    border-radius: 3px;
    margin-bottom: 10px;
  }

  .small {
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    text-align: center;
    color: #888888;
    display: inline-block;
  }
}
</style>

<style>
@media (min-width: 768px) {
  .wpp-modal .vm--modal {
    position: absolute !important;
    top: 95px !important;
    right: 195px !important;
    left: auto !important;
  }

  .call-modal .vm--modal {
    position: absolute !important;
    top: 95px !important;
    right: 10px !important;
    left: auto !important;
  }
}

@media (min-width: 1600px) {
  .wpp-modal .vm--modal {
    right: 20% !important;
  }

  .call-modal .vm--modal {
    right: 9% !important;
  }
}
</style>
