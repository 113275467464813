<template>
  <div class="row g-0 h-100 news-content">
    <div class="col-12 col-lg-6">
      <div class="info">
        <router-link :to="post.url" class="box">
          <div class="d-flex flex-row align-items-center justify-content-between">
            <span class="title">
              {{ post.title }}
            </span>
            <div class="date">{{ post.date }}</div>
          </div>
          <span class="text">
            {{ post.text }}
          </span>
        </router-link>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <img class="img-fluid" :src="`${post.imageUrl}`" :alt="post.title">
    </div>
  </div>
</template>

<script>
export default {
  name: "SlidePost",
  props: {
    post: {
      id: Number,
      imageUrl: String,
      title: String,
      date: String,
      text: String,
      url: String,
    }
  }
}
</script>

<style scoped lang="scss">
.news-content {
  z-index: 1;
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  .info {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @media (min-width: 768px) {

    }

    .box {
      width: 100%;
      background: #FFFFFF;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.02);
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      display: flex;
      flex-direction: column;
      justify-content: start;
      text-decoration: none;
      margin-top: 20px;

      @media (min-width: 768px) {
        margin-top: 100px;
        padding: 24px 0px 24px 23px;
        height: 270px;
      }

      &:hover {
        .text {
          text-decoration: underline;
        }
      }

      .title {
        background-color: #1B1B1E;
        width: 168px;
        height: 49px;
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
      }

      .date {
        font-weight: 400;
        font-size: 15px;
        line-height: 25px;
        color: #333333;
        margin-right: 30px;
      }

      .text {
        margin-top: 24px;
        font-size: 17px;
        text-decoration: none;
        line-height: 25px;
        color: #333333;

        @media (min-width: 768px) {
          height: 76px;
          width: 540px;
        }
      }
    }
  }
}
</style>
