<template>
  <div class="card-testimonial">
    <div class="top">
      <img :src="`${testimonial.imageUrl}`" :alt="testimonial.title">
      <div class="texts">
        <span class="title">
          {{ testimonial.title }}
        </span>
        <span class="subtitle">
          {{ testimonial.subtitle }}
        </span>
      </div>
    </div>
    <div class="body">
      <p>{{ testimonial.text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardTestimonial",
  props: {
    testimonial: {
      id: Number,
      title: String,
      subtitle: String,
      text: String,
      imageUrl: String,
    }
  }
}
</script>

<style scoped lang="scss">
.card-testimonial {
  padding: 50px 30px;
  position: relative;
  margin: 0 auto;
  background: #FFFFFF;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  color: #272727;
  transition: all ease-in-out 170ms;
  height: 381px;
  width: 85%;

  @media (min-width: 1920px) {
    height: 341px;
    width: 264px;
  }

  &:hover {
    cursor: pointer;
    transition: all ease-in-out 170ms;
    background: #222222;
    color: #FFFFFF;
  }

  .top {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .texts {
      margin-left: 18px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .title {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;

      }

      .subtitle {
        font-style: normal;
        font-size: 12px;
        line-height: 20px;
      }
    }
  }

  .body {
    margin-top: 30px;
  }
}
</style>
