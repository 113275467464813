<template>
  <div class="component-content container">
    <h1>Blog</h1>
    <div class="row header-page">
      <div class="col-12 col-lg-6">
        <router-link :to="mainPost.url" class="big-blog">
          <img class="img-big" :src="mainPost.imageUrl" alt="Noticia">
          <div class="info">
            <span class="header">
              <div class="tag">{{ mainPost.tag }}</div>
              <div class="date">
                <img src="../assets/images/icons/calendar.svg" alt="Calendario">
                <span>{{ mainPost.date }}</span>
              </div>
            </span>
            <div class="body">
              <p>{{ mainPost.title }}</p>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-12 col-lg-6 border-start list">
        <h3>Noticias mais lidas</h3>
        <ul class="list-unstyled">
          <LastBlogItem v-for="(post, index) in lastPosts" :key="index" :post="post"/>
        </ul>
      </div>
    </div>
    <hr>
    <h3>Últimas noticias</h3>
    <div class="grid-blog">
      <BlogCard v-for="(post, index) in posts" :key="index" :post="post"/>
    </div>
    <Paginator/>
  </div>
</template>

<script>
import Paginator from "@/components/Paginator";
import BlogCard from "@/components/BlogCard";
import LastBlogItem from "@/components/LastBlogItem";

export default {
  name: "Blog",
  components: {LastBlogItem, BlogCard, Paginator},
  data() {
    return {
      mainPost: {
        imageUrl: require("../assets/images/news/blog-1.png"),
        tag: "Audi e-tron GT",
        title: "Com a versão mais potente chegando aos 600 cv e uma autonomia de 470 cv, o e-tron GT é apresentado como o Audi mais evoluído de sempre.",
        date: "16/02/2021",
        url: {
          name: 'singlePost',
          params: {
            id: Math.floor(Math.random() * 100)
          }
        },
      },
      lastPosts: [
        {
          urlCover: require("../assets/images/news/mini-blog-1.png"),
          title: "Conheça a relação do escudo da porsche com o da Ferrari.",
          date: "20/01/2021",
          tag: "Porsche",
          url: {
            name: 'singlePost',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        },
        {
          urlCover: require("../assets/images/news/mini-blog-2.png"),
          title: "Conheça a relação do escudo da porsche com o da Ferrari.",
          date: "20/01/2021",
          tag: "BMW",
          url: {
            name: 'singlePost',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        },
        {
          urlCover: require("../assets/images/news/mini-blog-3.png"),
          title: "Conheça a relação do escudo da porsche com o da Ferrari.",
          date: "20/01/2021",
          tag: "Land Rover",
          url: {
            name: 'singlePost',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        },
      ],
      posts: [
        {
          urlCover: require("../assets/images/news/grid-blog-1.png"),
          title: "508 PSE. Já há preços para o Peugeot de produção mais potente de sempre.",
          date: "20/01/2021",
          tag: "BMW",
          url: {
            name: 'singlePost',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        },
        {
          urlCover: require("../assets/images/news/grid-blog-2.png"),
          title: "American muscle: a F-150 Raptor tem uma nova geração e vem aí um V8",
          date: "20/01/2021",
          tag: "Ford",
          url: {
            name: 'singlePost',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        },
        {
          urlCover: require("../assets/images/news/grid-blog-3.png"),
          title: "508 PSE. Já há preços para o Peugeot de produção mais potente de sempre.",
          date: "20/01/2021",
          tag: "Citroen",
          url: {
            name: 'singlePost',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        },
        {
          urlCover: require("../assets/images/news/grid-blog-1.png"),
          title: "508 PSE. Já há preços para o Peugeot de produção mais potente de sempre.",
          date: "20/01/2021",
          tag: "BMW",
          url: {
            name: 'singlePost',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        },
        {
          urlCover: require("../assets/images/news/grid-blog-2.png"),
          title: "American muscle: a F-150 Raptor tem uma nova geração e vem aí um V8",
          date: "20/01/2021",
          tag: "Ford",
          url: {
            name: 'singlePost',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        },
        {
          urlCover: require("../assets/images/news/grid-blog-3.png"),
          title: "508 PSE. Já há preços para o Peugeot de produção mais potente de sempre.",
          date: "20/01/2021",
          tag: "Citroen",
          url: {
            name: 'singlePost',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.component-content {

  h1 {
    margin-top: 130px;
    font-style: normal;
    margin-bottom: 36px;
    font-weight: 600;
    color: #272727;
    font-size: 30px;
    line-height: 33px;

    @media (min-width: 768px) {
      font-size: 35px;
      line-height: 44px;
    }
  }

  .header-page {
    @media (min-width: 768px) {
      margin-bottom: 55px;
    }

    .big-blog {
      text-decoration: none;
      padding-right: 27px;

      &:hover {
        .info {
          .body {
            p {
              text-decoration: underline;
            }
          }
        }
      }

      .img-big {
        margin-top: 0px;
        margin-bottom: 0px;
        width: 100%;
      }

      .info {
        margin-top: 40px;
        margin-bottom: 0px;

        .header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .tag {
            width: 168px;
            height: 49px;
            background: #333333;
            font-weight: 600;
            font-size: 18px;
            line-height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #FFFFFF;
          }

          .date {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 120px;
            align-items: center;
            color: #333333;
          }
        }

        .body {
          margin-top: 25px;
          margin-bottom: 0px;

          p {
            font-weight: normal;
            font-size: 23px;
            line-height: 32px;
            color: #333333;
          }
        }
      }
    }

    .list {
      @media (min-width: 768px) {
        padding-left: 40px;
      }

      h3 {
        margin-top: -4px;
        font-weight: 600;
        color: #272727;
        font-size: 20px;
        margin-bottom: 20px;
        line-height: 1;

        @media (min-width: 768px) {
          font-size: 25px;
          margin-bottom: 40px;
        }
      }

      ul {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 355px;

        @media (min-width: 768px) {
          padding-bottom: 60px;
          height: calc(100% - 40px);
        }
      }
    }
  }

  h3 {
    margin-top: 50px;
    margin-bottom: 35px;
    font-weight: 600;
    font-size: 25px;
    color: #272727;
  }

  .grid-blog {
    display: grid;
    position: relative;
    gap: 60px;
    margin-bottom: 120px;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));

    @media (min-width: 1920px) {
      grid-template-columns: repeat(auto-fill, minmax(410px, 1fr));
    }
  }
}
</style>
