<template>
  <div class="component-content container">
    <div class="filter-box bg-dark">
      <form action="!#" class="filter">
        <div class="inputs-list">
          <v-select append-to-body placeholder="Marca" :options="brands" class="custom-select"
                    name="filter_brand"
                    id="filter_brand"></v-select>
          <v-select append-to-body placeholder="Modelo" :options="models" class="custom-select"
                    name="filter_model"
                    id="filter_model"></v-select>
          <v-select append-to-body placeholder="Combustível" :options="fuels" class="custom-select"
                    name="filter_fuel"
                    id="filter_fuel"></v-select>
          <div v-if="windowWidth > 768" class="slide-range">
            <VueSimpleRangeSlider
                style="width: 100%"
                :min="1000"
                activeBarColor="#DCA66A"
                :max="100000"
                v-model="range"
            />
          </div>
          <div v-else class="together">
            <v-select append-to-body placeholder="Preço de" :options="prices" class="custom-select"
                      name="filter_price_from"
                      id="filter_price_from"></v-select>
            <v-select append-to-body placeholder="Preço até" :options="prices" class="custom-select"
                      name="filter_price_to"
                      id="filter_price_to"></v-select>
          </div>
          <button type="submit" class="btn btn-primary">
            <img src="../assets/images/icons/search.svg" alt="Search">
            <span>Pesquisar</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import VueSimpleRangeSlider from 'vue-simple-range-slider';
import 'vue-simple-range-slider/dist/vueSimpleRangeSlider.css'

export default {
  name: "FilterBox",
  components: {VueSimpleRangeSlider},
  data() {
    return {
      windowWidth: window.outerWidth,
      range: [5000, 60000],
      number: 10,
      brands: [
        "Ford",
        "Audi",
        "BMW",
        "Chevrolet",
        "Volvo",
        "Yamaha",
        "Renault",
        "Porsche  ",
        "Volkswagen  ",
      ],
      models: [
        "Fiesta",
        "Focus",
        "Furglaine",
        "Fusion",
        "Ka",
      ],
      prices: [
        "R$10.000,00",
        "R$20.000,00",
        "R$30.000,00"
      ],
      fuels: [
        "Gasolina",
        "Diesel",
        "Etanol",
        "Elétrico",
        "Híbrido",
      ],
    }
  }
}
</script>

<style scoped lang="scss">
.filter-box {
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
  border-radius: 3px;
  padding: 19px 19px 0px;
  height: 345px;
  transform: translate3d(0px, -425px, 0px);
  margin-bottom: -300px;

  @media (min-width: 414px) {
    transform: translate3d(0px, -395px, 0px);
  }

  @media (min-width: 768px) {
    margin-bottom: -50px;
    height: auto;
    transform: translate3d(0px, -110%, 0px);
  }

  @media (min-width: 1920px) {
    padding: 25px 25px 0px;
  }

  .filter {
    display: flex;
    justify-content: space-between;
    align-content: center;
    justify-items: center;
    width: 100%;
    flex-direction: column;

    .inputs-list {
      display: flex;
      justify-content: space-between;
      align-content: center;
      justify-items: center;
      width: 100%;
      flex-wrap: wrap;

      .custom-select {
        margin-bottom: 20px;
        width: 100%;

        @media (min-width: 768px) {
          width: calc(75% / 5);
        }

        @media (min-width: 1920px) {
          margin-bottom: 25px;
        }
      }

      .together {
        display: flex;
        width: 100%;

        @media (min-width: 768px) {
          width: calc((94% / 5) * 2 + 1.5%);
        }

        .custom-select {
          width: 50%;
        }
      }

      .slide-range {
        width: 100%;

        @media (min-width: 768px) {
          width: calc((75% / 4) * 1.4)
        }
      }

      .btn {
        border-radius: 3px;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        width: 100%;
        margin-top: 5px;

        @media (min-width: 768px) {
          margin-top: 0px;
          width: calc(94% / 5);
        }

        img {
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
