<template>
  <div @mouseover="mouseOver" @mouseleave="mouseLeave" class="card component-content">
    <div class="card-img-top">
      <router-link :to="vehicle.url">
        <SlideVehicleImages :images="vehicle.images" v-if="startSlide"/>
        <img v-else :src="vehicle.urlCover" :alt="vehicle.title">
      </router-link>
    </div>
    <div class="card-body">
      <router-link :to="vehicle.url">
        <h5 class="card-title">{{ vehicle.title }}</h5>
      </router-link>
      <div class="card-details d-flex flex-column justify-content-between">
        <router-link :to="vehicle.url" class="d-flex justify-content-between">
          <span class="card-version">{{ vehicle.version }}</span>
          <span class="card-price fw-normal">{{ vehicle.price }}</span>
        </router-link>
        <div class="stroke"></div>
        <div class="d-flex justify-content-between">
          <span class="card-calendar">
            <img src="../assets/images/icons/calendar.svg" alt="calendar-icon">
            {{ vehicle.registerYear }}
          </span>
          <span class="card-fuel">
            <img src="../assets/images/icons/fuel.svg" alt="fuel-icon">
            {{ vehicle.fuel }}
          </span>
          <span class="card-kms">
            <img src="../assets/images/icons/kms.svg" alt="kms-icon">
            {{ vehicle.kilometers }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'hooper/dist/hooper.css';
import SlideVehicleImages from "@/components/SlideVehicleImages";

export default {
  name: "Vehicle",
  components: {SlideVehicleImages},
  data() {
    return {
      windowWidth: window.outerWidth,
      startSlide: false,
    }
  },
  props: {
    vehicle: {
      id: Number,
      urlCover: String,
      images: Array,
      title: String,
      version: String,
      price: String,
      registerYear: String,
      fuel: String,
      kilometers: String,
      url: String,
    }
  },
  methods: {
    mouseOver() {
      if (this.windowWidth > 768) {
        this.startSlide = true;
      }
    },
    mouseLeave() {
      this.startSlide = false;
    },
  },
}
</script>

<style scoped lang="scss">
.card {
  transition: all ease-in-out 170ms;
  filter: drop-shadow(0px 10px 36px rgba(0, 0, 0, 0.1));
  display: flex;
  border: none;
  flex-direction: column;
  background-color: #FFFFFF;

  &:hover {
    transition: all ease-in-out 170ms;
    filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.3));
    cursor: pointer;
    background: #222222;

    .card-body {
      position: relative;
      height: 147px;

      a {
        .card-title {
          color: #FFFFFF;
        }
      }

      .card-details {
        color: #FFFFFF;

        img {
          filter: brightness(100);
        }

        a {
          color: #FFFFFF;
        }
      }
    }
  }


  .card-img-top {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;

    a {
      display: block;
      height: 100%;
      width: 100%;

      img {
        max-height: 100%;
        height: 100%;
        width: 100%;
        object-fit: unset;
      }
    }
  }

  .card-body {
    position: relative;
    height: 147px;

    a {
      text-decoration: none;

      .card-title {
        color: #1B1B1E;
        font-weight: 600;
        font-size: 17px;
        line-height: 23px;
      }

      .card-version {
        font-weight: 300;
      }

      .card-price {
        font-weight: 500;
        color: #DCA66A;
        font-size: 18px;
      }
    }

    .stroke {
      position: absolute;
      height: 0px;
      bottom: 57px;
      border-top: 1px solid #CACACA;
      left: 5%;
      width: 90%;
    }

    .card-details {
      height: 80px;
      cursor: pointer;

      a {
        color: #1B1B1E;
      }

      span {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: 300;

        img {
          margin-right: 4px;
        }
      }
    }
  }
}
</style>
