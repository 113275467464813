<template>
  <div class="component-content container">
    <h1>Pedido de avaliação de troca</h1>
    <p>Agradecemos desde já a sua mensagem. Por favor preencha os campos do presente formulário. Fale-nos da sua veículo
      com o maior detalhe possivel. Seremos muito breves na resposta ao seu pedido de avaliação.</p>
    <div class="contact-form">
      <form action="">
        <div class="row">
          <div class="form-group col-12 col-lg-4">
            <label for="contact_name">Nome</label>
            <input type="text" name="contact_name" id="contact_name" class="form-control">
          </div>
          <div class="form-group col-12 col-lg-4">
            <label for="contact_email">E-mail</label>
            <input type="email" name="contact_email" id="contact_email" class="form-control">
          </div>
          <div class="form-group col-12 col-lg-4">
            <label for="contact_phone">Telefone</label>
            <input type="tel" name="contact_phone" id="contact_phone" class="form-control">
          </div>
          <div class="form-group col-12">
            <label for="contact_message">Mensagem</label>
            <textarea name="contact_message" id="contact_message" rows="10" class="form-control"></textarea>
          </div>
          <span class="small">Ao enviar este formulário está nos dando autorização para utilização dos seus dados
            pessoais e
            concordando
            com a nossa Política Privacidade.
          </span>
          <div class="col-12 col-lg-3 ms-auto text-end">
            <button type="submit" class="btn btn-primary">Enviar pedido</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "RecoveryAssessment"
}
</script>


<style scoped lang="scss">
.component-content {
  margin-top: 130px;

  h1 {
    font-style: normal;
    margin-bottom: 36px;
    font-weight: 600;
    font-size: 30px;
    line-height: 33px;
    color: #272727;

    @media (min-width: 768px) {
      font-size: 35px;
      line-height: 44px;
    }
  }

  p {
    margin-bottom: 0px;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    color: #333333;
    width: 100%;

    @media (min-width: 768px) {
      width: 53%;
    }
  }

  .contact-form {
    margin-top: 74px;

    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      line-height: 69px;
      color: #272727;
    }

    label {
      font-weight: normal;
      font-size: 19px;
      line-height: 30px;
      color: #333333;
      margin-bottom: 10px;
    }

    .form-control {
      height: 53px;
      background: #FFFFFF;
      border: 1px solid #E7E7E7;
      box-sizing: border-box;
      border-radius: 3px;
      margin-bottom: 30px;
    }

    textarea.form-control {
      height: 150px;
      margin-bottom: 10px;
    }

    .btn {
      height: 68px;
      font-weight: normal;
      font-size: 19px;
      line-height: 30px;
      width: 215px;
      margin-bottom: 75px;
    }
  }

  .small {
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    text-align: right;
    margin-bottom: 10px;
    color: #888888;
    display: inline-block;
  }
}
</style>
