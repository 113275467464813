<template>
  <div class="component-content">
    <div v-if="windowWidth < 768" class="action-buttons">
      <button @click="showFilters = !showFilters, showOrder = false" class="btn">
        <img src="../assets/images/icons/filters.svg" alt="Filtro">
        <span>Filtrar</span>
      </button>
      <button @click="showOrder = !showOrder, showFilters = false" class="btn">
        <img src="../assets/images/icons/order.svg" alt="Ordenar">
        <span>Ordenar</span>
      </button>
      <button @click="showFilters = false, showOrder = false" v-if="showFilters || showOrder"
              class="btn brn-link ms-auto close">
        <img src="../assets/images/icons/close.svg" alt="Fechar">
      </button>
    </div>
    <div class="container mob-actions">
      <div v-show="showFilters" v-if="windowWidth < 768" class="mobile-filter">
        <FilterInputsList/>
      </div>
      <div v-show="showOrder" v-if="windowWidth < 768" class="mobile-order custom-select">
        <div class="form-check form-check-inline d-flex justify-content-between border-bottom p-2"
             v-for="(order, index) in orderOptions" :key="index">
          <label class="form-check-label" :for="`order_${index}`">{{ order }}</label>
          <input v-model="defaultSelected" @change="filterData" class="form-check-input" type="radio"
                 name="order" :id="`order_${index}`"
                 :value="order">
        </div>
      </div>
    </div>
    <div class="container bg-blue">
      <h1>Encontre o seu veículo</h1>
      <div class="filter-box">
        <FilterInputsList v-if="windowWidth > 768"/>
      </div>
      <div class="top-actions">
        <span>Total de veículos: 136</span>
        <div class="order-control">
          <span>Ordenar por:</span>
          <div class="select-custom">
            <v-select v-model="defaultSelected" :options="orderOptions"/>
          </div>
        </div>
      </div>

      <VehiclesGrid :type="`list`" :vehicles="vehicles"/>

      <Paginator/>
    </div>
  </div>
</template>

<script>

import VehiclesGrid from "@/components/VehiclesGrid";
import FilterInputsList from "@/components/FilterInputsList";
import Paginator from "@/components/Paginator";

export default {
  name: "Vehicles",
  components: {Paginator, FilterInputsList, VehiclesGrid},
  data() {
    return {
      showFilters: false,
      showOrder: false,
      windowWidth: window.outerWidth,
      defaultSelected: "Recentes",
      orderOptions: [
        "Recentes",
        "Antigos"
      ],
      vehicles: [
        {
          urlCover: require("../assets/images/vehicles/mercedes.png"),
          images: [
            require("../assets/images/vehicles/mercedes.png"),
            require("../assets/images/vehicles/honda.png"),
            require("../assets/images/vehicles/bmw.png"),
          ],
          title: "Mercedes-Benz R 320",
          version: "Cdi 4-Matic Longo",
          price: "R$ 24.900",
          registerYear: "18/19",
          fuel: "Gasolina",
          kilometers: "10.000Km",
          url: {
            name: 'singleVehicle',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        },
        {
          urlCover: require("../assets/images/vehicles/honda.png"),
          images: [
            require("../assets/images/vehicles/honda.png"),
            require("../assets/images/vehicles/mercedes.png"),
            require("../assets/images/vehicles/bmw.png"),
          ],
          title: "Honda E",
          version: "100% elétrico",
          price: "R$ 24.900",
          registerYear: "18/19",
          fuel: "Elétrico",
          kilometers: "10.000Km",
          url: {
            name: 'singleVehicle',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        },
        {
          urlCover: require("../assets/images/vehicles/bmw.png"),
          images: [
            require("../assets/images/vehicles/bmw.png"),
            require("../assets/images/vehicles/mini.png"),
            require("../assets/images/vehicles/bmw.png"),
          ],
          title: "Bmw m4",
          version: "3.2cc ",
          price: "R$ 24.900",
          registerYear: "18/19",
          fuel: "Gasolina",
          kilometers: "10.000Km",
          url: {
            name: 'singleVehicle',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        },
        {
          urlCover: require("../assets/images/vehicles/mini.png"),
          images: [
            require("../assets/images/vehicles/mini.png"),
            require("../assets/images/vehicles/bmw.png"),
            require("../assets/images/vehicles/bmw.png"),
          ],
          title: "Bmw m4",
          version: "3.2cc ",
          price: "R$ 24.900",
          registerYear: "18/19",
          fuel: "Gasolina",
          kilometers: "10.000Km",
          url: {
            name: 'singleVehicle',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        },
        {
          urlCover: require("../assets/images/vehicles/mercedes.png"),
          images: [
            require("../assets/images/vehicles/mercedes.png"),
            require("../assets/images/vehicles/honda.png"),
            require("../assets/images/vehicles/bmw.png"),
          ],
          title: "Mercedes-Benz R 320",
          version: "Cdi 4-Matic Longo",
          price: "R$ 24.900",
          registerYear: "18/19",
          fuel: "Gasolina",
          kilometers: "10.000Km",
          url: {
            name: 'singleVehicle',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        },
        {
          urlCover: require("../assets/images/vehicles/honda.png"),
          images: [
            require("../assets/images/vehicles/honda.png"),
            require("../assets/images/vehicles/mercedes.png"),
            require("../assets/images/vehicles/bmw.png"),
          ],
          title: "Honda E",
          version: "100% elétrico",
          price: "R$ 24.900",
          registerYear: "18/19",
          fuel: "Elétrico",
          kilometers: "10.000Km",
          url: {
            name: 'singleVehicle',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        },
        {
          urlCover: require("../assets/images/vehicles/bmw.png"),
          images: [
            require("../assets/images/vehicles/bmw.png"),
            require("../assets/images/vehicles/mini.png"),
            require("../assets/images/vehicles/bmw.png"),
          ],
          title: "Bmw m4",
          version: "3.2cc ",
          price: "R$ 24.900",
          registerYear: "18/19",
          fuel: "Gasolina",
          kilometers: "10.000Km",
          url: {
            name: 'singleVehicle',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        },
        {
          urlCover: require("../assets/images/vehicles/mini.png"),
          images: [
            require("../assets/images/vehicles/mini.png"),
            require("../assets/images/vehicles/bmw.png"),
            require("../assets/images/vehicles/bmw.png"),
          ],
          title: "Bmw m4",
          version: "3.2cc ",
          price: "R$ 24.900",
          registerYear: "18/19",
          fuel: "Gasolina",
          kilometers: "10.000Km",
          url: {
            name: 'singleVehicle',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        },
        {
          urlCover: require("../assets/images/vehicles/mercedes.png"),
          images: [
            require("../assets/images/vehicles/mercedes.png"),
            require("../assets/images/vehicles/honda.png"),
            require("../assets/images/vehicles/bmw.png"),
          ],
          title: "Mercedes-Benz R 320",
          version: "Cdi 4-Matic Longo",
          price: "R$ 24.900",
          registerYear: "18/19",
          fuel: "Gasolina",
          kilometers: "10.000Km",
          url: {
            name: 'singleVehicle',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        },
        {
          urlCover: require("../assets/images/vehicles/honda.png"),
          images: [
            require("../assets/images/vehicles/honda.png"),
            require("../assets/images/vehicles/mercedes.png"),
            require("../assets/images/vehicles/bmw.png"),
          ],
          title: "Honda E",
          version: "100% elétrico",
          price: "R$ 24.900",
          registerYear: "18/19",
          fuel: "Elétrico",
          kilometers: "10.000Km",
          url: {
            name: 'singleVehicle',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        },
        {
          urlCover: require("../assets/images/vehicles/bmw.png"),
          images: [
            require("../assets/images/vehicles/bmw.png"),
            require("../assets/images/vehicles/mini.png"),
            require("../assets/images/vehicles/bmw.png"),
          ],
          title: "Bmw m4",
          version: "3.2cc ",
          price: "R$ 24.900",
          registerYear: "18/19",
          fuel: "Gasolina",
          kilometers: "10.000Km",
          url: {
            name: 'singleVehicle',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        },
        {
          urlCover: require("../assets/images/vehicles/mini.png"),
          images: [
            require("../assets/images/vehicles/mini.png"),
            require("../assets/images/vehicles/bmw.png"),
            require("../assets/images/vehicles/bmw.png"),
          ],
          title: "Bmw m4",
          version: "3.2cc ",
          price: "R$ 24.900",
          registerYear: "18/19",
          fuel: "Gasolina",
          kilometers: "10.000Km",
          url: {
            name: 'singleVehicle',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        },
        {
          urlCover: require("../assets/images/vehicles/mercedes.png"),
          images: [
            require("../assets/images/vehicles/mercedes.png"),
            require("../assets/images/vehicles/honda.png"),
            require("../assets/images/vehicles/bmw.png"),
          ],
          title: "Mercedes-Benz R 320",
          version: "Cdi 4-Matic Longo",
          price: "R$ 24.900",
          registerYear: "18/19",
          fuel: "Gasolina",
          kilometers: "10.000Km",
          url: {
            name: 'singleVehicle',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        },
        {
          urlCover: require("../assets/images/vehicles/honda.png"),
          images: [
            require("../assets/images/vehicles/honda.png"),
            require("../assets/images/vehicles/mercedes.png"),
            require("../assets/images/vehicles/bmw.png"),
          ],
          title: "Honda E",
          version: "100% elétrico",
          price: "R$ 24.900",
          registerYear: "18/19",
          fuel: "Elétrico",
          kilometers: "10.000Km",
          url: {
            name: 'singleVehicle',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        },
        {
          urlCover: require("../assets/images/vehicles/bmw.png"),
          images: [
            require("../assets/images/vehicles/bmw.png"),
            require("../assets/images/vehicles/mini.png"),
            require("../assets/images/vehicles/bmw.png"),
          ],
          title: "Bmw m4",
          version: "3.2cc ",
          price: "R$ 24.900",
          registerYear: "18/19",
          fuel: "Gasolina",
          kilometers: "10.000Km",
          url: {
            name: 'singleVehicle',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        },
        {
          urlCover: require("../assets/images/vehicles/mini.png"),
          images: [
            require("../assets/images/vehicles/mini.png"),
            require("../assets/images/vehicles/bmw.png"),
            require("../assets/images/vehicles/bmw.png"),
          ],
          title: "Bmw m4",
          version: "3.2cc ",
          price: "R$ 24.900",
          registerYear: "18/19",
          fuel: "Gasolina",
          kilometers: "10.000Km",
          url: {
            name: 'singleVehicle',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        },
      ]
    }
  },
  methods: {
    filterData() {
      this.showOrder = false
      console.log(this.defaultSelected);
      console.log('Filtrar dados e atualizar a lista');
    }
  }
}
</script>

<style scoped lang="scss">
.component-content {
  .action-buttons {
    padding: 0px 16px;
    height: 80px;
    background-color: #FFF;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .btn {
      margin-right: 12px;
      width: 106px;
      height: 35px;
      background: #F6F8FA;
      box-shadow: 0px 3px 4px rgba(86, 86, 86, 0.15);
      border-radius: 3px;
      color: #272727;
      display: flex;
      justify-content: center;
      align-items: center;

      &.close {
        background-color: transparent;
        width: 18px;
        height: 18px;
        padding: 0px;
        box-shadow: none;
        margin: 0px;
      }

      img {
        margin-right: 8px;
      }
    }
  }

  .mobile-order {
    width: 100%;

    .select-custom {
      height: 54px;
    }

    .btn {
      width: 100%;
      margin-top: 10px;
    }
  }

  .mob-actions {
    padding-bottom: 40px;
  }

  .bg-blue {
    background-color: #F6F8FA;

    @media (min-width: 768px) {
      background-color: transparent;
    }
  }

  h1 {
    font-style: normal;
    margin-bottom: 36px;
    font-weight: 600;
    font-size: 35px;
    line-height: 44px;
    color: #272727;
    display: none;

    @media (min-width: 768px) {
      display: flex;
    }
  }

  .filter-box {
    margin-top: 5px;
    margin-bottom: 20px;
    position: relative;
    display: none;

    @media (min-width: 768px) {
      display: flex;
    }
  }

  .top-actions {
    margin-bottom: 10px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    display: none;

    @media (min-width: 768px) {
      display: flex;
    }

    span {
      font-size: 16px;
      line-height: 20px;
      color: #333333;
    }

    .order-control {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .span {
        font-size: 16px;
      }

      .select-custom {
        margin-left: 10px;
        width: 150px;
      }
    }
  }
}
</style>

<style>
.custom-select .vs__dropdown-toggle {
  background: #FFFFFF !important;
  border-radius: 3px !important;
  border: 1px solid #E7E7E7 !important;
}

.together .custom-select:nth-child(1) .vs__dropdown-toggle {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.together .custom-select:nth-child(2) .vs__dropdown-toggle {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
</style>
