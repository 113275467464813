<template>
  <div class="component-content testimonials">
    <h3>O que os nossos clientes dizem sobre nós</h3>
    <div class="container">
      <div class="slide-container">
        <hooper :settings="hooperSettings">
          <slide v-for="(testimonial, index) in testimonials" :key="index" :index="index">
            <CardTestimonial :testimonial="testimonial"></CardTestimonial>
          </slide>
          <hooper-navigation v-if="windowWidth > 768" slot="hooper-addons"></hooper-navigation>
        </hooper>
      </div>
    </div>
  </div>
</template>

<script>
import CardTestimonial from "@/components/CardTestimonial";
import {Hooper, Slide, Navigation as HooperNavigation} from "hooper";

export default {
  name: "ListTestemonial",
  components: {
    CardTestimonial,
    Hooper,
    HooperNavigation,
    Slide,
  },
  data() {
    return {
      windowWidth: window.outerWidth,
      total: 0,
      hooperSettings: {
        autoPlay: true,
        infiniteScroll: true,
        playSpeed: 3000,
        wheelControl: false,
        itemsToShow: 1,
        width: 300,
        breakpoints: {
          800: {
            itemsToShow: 3
          },
          1000: {
            itemsToShow: 4,
          }
        }
      },
      testimonials: [
        {
          title: "Clara Gonzalez",
          subtitle: "Switzerland",
          text: "«Many people were hoping that if the Democrats won control of Congress they would reverse the online»",
          imageUrl: require("../assets/images/testimonials/image-1.png"),
        },
        {
          title: "Della Colon",
          subtitle: "Slovenia",
          text: "«With so many different ways today to find information online, it can sometimes be hard to know where»",
          imageUrl: require("../assets/images/testimonials/image-2.png"),
        },
        {
          title: "Randall Zimmerman",
          subtitle: "Wallis and Futuna",
          text: "«Computer users and programmers have become so accustomed to using Windows, even for the changing»",
          imageUrl: require("../assets/images/testimonials/image-3.png"),
        },
        {
          title: "Lela Stokes",
          subtitle: "Lebanon",
          text: "«With easy access to Broadband and DSL the number of people using the Internet has skyrocket»",
          imageUrl: require("../assets/images/testimonials/image-4.png"),
        },
        {
          title: "Randall Zimmerman",
          subtitle: "Wallis and Futuna",
          text: "«Computer users and programmers have become so accustomed to using Windows, even for the changing»",
          imageUrl: require("../assets/images/testimonials/image-3.png"),
        },
        {
          title: "Della Colon",
          subtitle: "Slovenia",
          text: "«With so many different ways today to find information online, it can sometimes be hard to know where»",
          imageUrl: require("../assets/images/testimonials/image-2.png"),
        },
        {
          title: "Lela Stokes",
          subtitle: "Lebanon",
          text: "«With easy access to Broadband and DSL the number of people using the Internet has skyrocket»",
          imageUrl: require("../assets/images/testimonials/image-4.png"),
        },
        {
          title: "Clara Gonzalez",
          subtitle: "Switzerland",
          text: "«Many people were hoping that if the Democrats won control of Congress they would reverse the online»",
          imageUrl: require("../assets/images/testimonials/image-1.png"),
        },
      ],
    }
  }
}
</script>

<style scoped lang="scss">
.component-content {
  margin-bottom: 100px;

  h3 {
    margin-bottom: 80px;
    font-weight: 600;
    text-align: center;
    color: #272727;
    font-size: 28px;
    line-height: 34px;

    @media (min-width: 1920px) {
      line-height: 42px;
      font-size: 35px;
    }
  }
}
</style>
