import Vue from "vue"
import Router from "vue-router"

Vue.use(Router)

import Home from "../components/Home"
import Vehicles from "../components/Vehicles"
import Services from "@/components/Services";
import About from "@/components/About";
import Blog from "@/components/Blog";
import Contacts from "@/components/Contacts";
import Details from "@/components/Details";
import Post from "@/components/Post";
import RecoveryAssessment from "@/components/RecoveryAssessment";

const routes = [
    {
        name: 'home',
        path: '/',
        component: Home
    },
    {
        name: 'vehicles',
        path: '/veiculos',
        component: Vehicles,
    },
    {
        name: 'singleVehicle',
        path: '/veiculo/:id',
        component: Details,
    },
    {
        name: 'recovery-assessment',
        path: '/veiculo/avaliacao-de-troca',
        component: RecoveryAssessment,
    },
    {
        name: 'about',
        path: '/sobre-nos',
        component: About
    },
    {
        name: 'services',
        path: '/servicos',
        component: Services
    },
    {
        name: 'blog',
        path: '/blog',
        component: Blog,
    },
    {
        name: 'singlePost',
        path: '/blog/post/:id',
        component: Post,
    },
    {
        name: 'contacts',
        path: '/contatos',
        component: Contacts
    }
]

const router = new Router({
    routes,
    scrollBehavior() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({x: 0, y: 0})
            }, 50)
        })
    }

})

export default router;
