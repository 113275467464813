<template>
  <div class="component-content container">
    <h1>Contatos</h1>
    <p>Para qualquer esclarecimento, contate-nos.</p>
    <div class="address-container">
      <div class="row">
        <div class="col-12 col-lg-6">
          <div v-if="windowWidth > 768" class="address-buttons">
            <ul class="link-secondary list-unstyled">
              <li class="addresses" v-for="address in addresses" :key="address.id">
                <button v-bind:class="address.id === visible ? 'active' : ''" class="btn btn-sm"
                        @click="changeAddress(address.id)">
                  {{ address.title }}
                </button>
              </li>
            </ul>
          </div>
          <div v-else class="address-select">
            <select class="custom-select" v-model="visible" @change="changeAddress(visible)">
              <option :value="address.id" v-for="address in addresses" :key="address.id"
                      v-bind:selected="address.id === visible">
                {{ address.title }}
              </option>
            </select>
          </div>

          <div v-show="visible === address.id" v-for="address in addresses" :key="address.id">
            <div class="address-info">
              <div class="info">
                <h4>Endereço</h4>
                <ul class="list-unstyled">
                  <li>{{ address.address }}</li>
                </ul>
              </div>
              <div class="info">
                <h4>
                  Horário de trabalho
                </h4>
                <ul class="list-unstyled">
                  <li :key="idx1" v-for="(time, idx1) in address.workSchedule">{{ time }}</li>
                </ul>
              </div>
              <div class="info">
                <h4>Telefone</h4>
                <ul class="list-unstyled">
                  <li :key="idx2" v-for="(phone, idx2) in address.phones">{{ phone }}</li>
                </ul>
              </div>
              <div class="info">
                <h4>Email</h4>
                <ul class="list-unstyled">
                  <li :key="idx3" v-for="(email, idx3) in address.emails">{{ email }}</li>
                </ul>
              </div>
            </div>
            <div class="social-links-container">
              <span>Redes Sociais</span>
              <SocialButtons/>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 col-map">
          <div v-show="visible === address.id" v-for="address in addresses" :key="address.id" class="address-map"
               v-html="address.map_url"></div>
        </div>
      </div>
    </div>
    <div class="contact-form">
      <h3>Fale conosco e nos envie a sua mensagem!</h3>
      <form action="">
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="form-group">
              <label for="contact_name">Nome</label>
              <input type="text" name="contact_name" id="contact_name" class="form-control">
            </div>
            <div class="form-group">
              <label for="contact_email">E-mail</label>
              <input type="email" name="contact_email" id="contact_email" class="form-control">
            </div>
            <div class="form-group">
              <label for="contact_phone">Telefone</label>
              <input type="tel" name="contact_phone" id="contact_phone" class="form-control">
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="form-group">
              <label for="contact_message">Mensagem</label>
              <textarea name="contact_message" id="contact_message" rows="10" class="form-control"></textarea>
            </div>
          </div>
          <span class="small">Ao enviar este formulário está nos dando autorização para utilização dos seus dados
            pessoais e
            concordando
            com a nossa Política Privacidade.
          </span>
          <div class="col-12 col-lg-3 ms-auto text-end">
            <button type="submit" class="btn btn-primary">Enviar mensagem</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import SocialButtons from "@/components/SocialButtons";

export default {
  name: "Contacts",
  components: {SocialButtons},
  data() {
    return {
      windowWidth: window.outerWidth,
      visible: 1,
      addresses: [
        {
          id: 1,
          title: "Av. António Farinha Pereira - Alferrarede",
          address: "Av. António Farinha Pereira, Nº28 2200-024 - São Caetano do Sul",
          workSchedule: [
            "Segunda a Sexta: 09:30 ás 20:00",
            "Sábado: 09:30 ás 20:00",
            "Domingo: Fechado"
          ],
          phones: [
            "+351 229 370 729"
          ],
          emails: [
            "geral@mystand.pt"
          ],
          map_url: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d467689.7971944828!2d-46.8754915172196!3d-23.68216038895823!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce448183a461d1%3A0x9ba94b08ff335bae!2zU8OjbyBQYXVsbywgU1A!5e0!3m2!1spt-BR!2sbr!4v1616163366727!5m2!1spt-BR!2sbr" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe>'
        },
        {
          id: 2,
          title: "Av. António Farinha Pereira - Alferrarede",
          address: "Av. Paulista, Nº28 2200-024 - São Paulo",
          workSchedule: [
            "Segunda a Sexta: 09:30 ás 20:00",
            "Sábado: 09:30 ás 20:00",
            "Domingo: Fechado"
          ],
          phones: [
            "+351 229 370 729"
          ],
          emails: [
            "geral@mystand.pt"
          ],
          map_url: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d467689.7971944828!2d-46.8754915172196!3d-23.68216038895823!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce448183a461d1%3A0x9ba94b08ff335bae!2zU8OjbyBQYXVsbywgU1A!5e0!3m2!1spt-BR!2sbr!4v1616163366727!5m2!1spt-BR!2sbr" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe>'
        },
        {
          id: 9,
          title: "Av. António Farinha Pereira - Alferrarede",
          address: "Av. António Farinha Pereira, Nº28 2200-024 - São Caetano do Sul",
          workSchedule: [
            "Segunda a Sexta: 09:30 ás 20:00",
            "Sábado: 09:30 ás 20:00",
            "Domingo: Fechado"
          ],
          phones: [
            "+351 229 370 729"
          ],
          emails: [
            "geral@mystand.pt"
          ],
          map_url: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d467689.7971944828!2d-46.8754915172196!3d-23.68216038895823!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce448183a461d1%3A0x9ba94b08ff335bae!2zU8OjbyBQYXVsbywgU1A!5e0!3m2!1spt-BR!2sbr!4v1616163366727!5m2!1spt-BR!2sbr" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe>'
        },
        {
          id: 8,
          title: "Av. António Farinha Pereira - Alferrarede",
          address: "Av. Paulista, Nº28 2200-024 - São Paulo",
          workSchedule: [
            "Segunda a Sexta: 09:30 ás 20:00",
            "Sábado: 09:30 ás 20:00",
            "Domingo: Fechado"
          ],
          phones: [
            "+351 229 370 729"
          ],
          emails: [
            "geral@mystand.pt"
          ],
          map_url: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d467689.7971944828!2d-46.8754915172196!3d-23.68216038895823!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce448183a461d1%3A0x9ba94b08ff335bae!2zU8OjbyBQYXVsbywgU1A!5e0!3m2!1spt-BR!2sbr!4v1616163366727!5m2!1spt-BR!2sbr" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe>'
        },
        {
          id: 3,
          title: "Av. António Farinha Pereira - Alferrarede",
          address: "Rua Pedro Pereira, Nº28 2200-024 - Centro",
          workSchedule: [
            "Segunda a Sexta: 08:30 ás 19:00",
            "Sábado: 09:30 ás 20:00",
            "Domingo: 08:30 ás 13:00"
          ],
          phones: [
            "+351 229 370 729"
          ],
          emails: [
            "aldeota@mystand.pt"
          ],
          map_url: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d467689.7971944828!2d-46.8754915172196!3d-23.68216038895823!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce448183a461d1%3A0x9ba94b08ff335bae!2zU8OjbyBQYXVsbywgU1A!5e0!3m2!1spt-BR!2sbr!4v1616163366727!5m2!1spt-BR!2sbr" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe>'
        },
        {
          id: 4,
          title: "Av. Rui Alfredo Pereira - Alferrarede",
          address: "Rua José Carlos, Nº28 2200-024 - Centro",
          workSchedule: [
            "Segunda a Sexta: 08:30 ás 19:00",
            "Sábado: 09:30 ás 20:00",
            "Domingo: 08:30 ás 13:00"
          ],
          phones: [
            "+351 229 370 729"
          ],
          emails: [
            "aldeota@mystand.pt"
          ],
          map_url: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d467689.7971944828!2d-46.8754915172196!3d-23.68216038895823!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce448183a461d1%3A0x9ba94b08ff335bae!2zU8OjbyBQYXVsbywgU1A!5e0!3m2!1spt-BR!2sbr!4v1616163366727!5m2!1spt-BR!2sbr" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe>'
        }
      ]
    }
  },
  methods: {
    changeAddress(id) {
      this.visible = id
    }
  }
}
</script>

<style scoped lang="scss">
.component-content {
  margin-top: 130px;

  h1 {
    font-style: normal;
    margin-bottom: 0px;
    font-weight: 600;
    color: #272727;
    font-size: 30px;
    line-height: 33px;

    @media (min-width: 768px) {
      font-size: 35px;
      line-height: 44px;
    }
  }

  p {
    margin-bottom: 0px;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    color: #333333;
  }

  .address-select {
    width: 100%;

    select {
      font-weight: bold;
      width: 100%;
      height: 49px;
      padding: 0px 10px;
    }
  }

  .address-buttons {
    margin-bottom: 0px;

    ul {
      display: grid;
      gap: 25px 16px;
      grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
      position: relative;
    }

    .btn {
      width: 100%;
      height: 65px;
      font-weight: normal;
      font-size: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      color: #333333;
      margin-right: 14px;
      padding: 5px 15px;
      border: 1px solid #1B1B1E;
      border-radius: 3px;

      &.active {
        color: #FFFFFF;
        background: #333333;
      }

    }
  }

  .address-container {
    margin-top: 30px;
    margin-bottom: 0px;

    .address-info {
      display: grid;
      position: relative;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      margin-top: 15px;

      @media (min-width: 768px) {
        margin-top: 45px;
        gap: 45px;
      }

      h4 {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 30px;
      }

      ul {
        li {
          font-weight: normal;
          font-size: 16px;
          line-height: 22px;
          color: #333333;
        }
      }
    }
  }

  .social-links-container {
    margin-bottom: 0px;
    margin-top: 20px;

    @media (min-width: 768px) {
      margin-top: 50px;
    }

    span {
      font-weight: normal;
      font-size: 14px;
      line-height: 30px;
      color: #333333;
    }
  }

  .col-map {
    @media (max-width: 768px) {
      margin-top: 30px;
      padding: 0px;
      height: 200px;
    }

    .address-map {
      height: 100%;
      display: flex;
    }
  }

  .contact-form {
    margin-top: 45px;

    @media (min-width: 768px) {
      margin-top: 100px;
    }

    h3 {
      font-style: normal;
      font-weight: 600;
      color: #272727;
      font-size: 22px;
      line-height: 30px;
      margin-bottom: 30px;

      @media (min-width: 768px) {
        font-size: 25px;
        line-height: 69px;
      }
    }

    label {
      font-weight: normal;
      font-size: 19px;
      line-height: 30px;
      color: #333333;
      margin-bottom: 10px;
    }

    .form-control {
      height: 53px;
      background: #FFFFFF;
      border: 1px solid #E7E7E7;
      box-sizing: border-box;
      border-radius: 3px;
      margin-bottom: 20px;
    }

    textarea.form-control {
      height: 278px;
      margin-bottom: 10px;
    }

    .btn {
      height: 68px;
      font-weight: normal;
      font-size: 19px;
      line-height: 30px;
      width: 215px;
      margin-bottom: 75px;
    }
  }

  .small {
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    text-align: right;
    margin-bottom: 15px;
    color: #888888;
    display: inline-block;
  }
}
</style>
