<template>
  <SplideSlide>
    <router-link :to="slide.url" class="slide-body">
      <img class="img-fluid" :src="`${slide.imageUrl}`">
      <div class="slide-details">
        <div class="text">
          <span class="slide-title">
            {{ slide.title }}
          </span>
          <span class="slide-price">
            {{ slide.price }}
          </span>
        </div>
      </div>
    </router-link>
  </SplideSlide>
</template>

<script>
import {SplideSlide} from '@splidejs/vue-splide';

export default {
  name: "SlideTop",
  components: {
    SplideSlide
  },
  props: {
    slide: {
      id: Number,
      imageUrl: String,
      title: String,
      price: String,
      url: String
    }
  }
}
</script>

<style scoped lang="scss">
.slide-body {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;

  .slide-details {
    width: 85%;
    position: absolute;
    left: 30px;
    bottom: 18px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .slide-title {
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      color: #FFFFFF;
    }

    .slide-price {
      font-size: 18px;
      line-height: 25px;
      color: #FFFFFF;
    }

    .btn-primary {
      height: 45px;
      width: 45px;
      font-size: 20px;
      line-height: 1;
      display: flex;
      justify-content: center;
      align-items: center;

    }
  }
}
</style>
