<template>
  <div class="page">
    <Navbar/>

    <main role="main">
      <router-view/>
    </main>

    <Footer/>
  </div>
</template>

<script>

import Navbar from "@/components/partials/Navbar";
import Footer from "@/components/partials/Footer";

export default {
  name: 'App',
  components: {Footer, Navbar}
}
</script>

<style>
.page {
  height: 100%;
}

main {
  margin-top: 88px;
  min-height: calc(100vh - 388px);
}
</style>
