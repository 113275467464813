<template>
  <div class="page-content">
    <FsLightbox
        :slide="slide"
        :toggler="toggler"
        :sources="bigImages"
    />
    <div v-if="windowWidth < 768" class="slide-mobile">
      <Splide :options="primaryOptions" ref="primary">
        <SplideSlide v-for="(image, index) in bigImages" :key="index">
          <img :data-index="index + 1" :src="image" alt="Veículo">
        </SplideSlide>
      </Splide>
    </div>
    <div class="component-content container">
      <div class="row g-0 header">
        <div class="col title">
          <h1>Mercedes-Benz R 320</h1>
          <p>
            Cdi 4-Matic Longo
          </p>
          <div v-if="windowWidth > 768" class="brand-logo">
            <img src="../assets/images/mercedes-benz.svg" alt="Mercedes">
          </div>
        </div>
        <div class="col price">
          <h2>R$ 54.000</h2>
          <p>
            Financiamento sobre consulta
          </p>
          <ul class="list-unstyled">
            <li class="get-info">
              <button @click="scrollToForm" class="btn btn-primary">
                <img src="../assets/images/icons/circle-info.svg" alt="Carro">
                <span>Pedir informações</span>
              </button>
            </li>
            <li>
              <router-link :to="{name:'recovery-assessment'}" class="btn btn-light">
                <img src="../assets/images/icons/car.svg" alt="Carro">
                <span>Avaliar Troca</span>
              </router-link>
            </li>
            <li>
              <a class="btn btn-light" href="/">
                <img src="../assets/images/icons/share.svg" alt="Compartilhar">
                <span>Compartilhar</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="row main">
        <div v-if="windowWidth > 768" class="col-12 col-lg-7">
          <Splide :options="primaryOptions" ref="primary">
            <SplideSlide v-for="(image, index) in bigImages" :key="index">
              <img :data-index="index + 1" :src="image" alt="Veículo">
            </SplideSlide>
          </Splide>
        </div>
        <hr v-if="windowWidth < 678">
        <div class="col-12 col-lg-5 ps-lg-5">
          <div class="top">
            <h3>Detalhes</h3>
            <div v-if="windowWidth > 678" class="line"></div>
          </div>
          <div class="info-grid">
            <div class="item">
              <span class="label">Categoria</span>
              <span class="item-value">Sedan</span>
            </div>
            <div class="item">
              <span class="label">Ano</span>
              <span class="item-value">Agosto 2020</span>
            </div>
            <div class="item">
              <span class="label">Quilometragem</span>
              <span class="item-value">120.000 Km</span>
            </div>
            <div class="item">
              <span class="label">Cilindrada</span>
              <span class="item-value">2.500 cc</span>
            </div>
            <div class="item">
              <span class="label">Câmbio</span>
              <span class="item-value">Automático</span>
            </div>
            <div class="item">
              <span class="label">Potência</span>
              <span class="item-value">620cv</span>
            </div>
            <div class="item">
              <span class="label">Combustivel</span>
              <span class="item-value">Gasolina</span>
            </div>
            <div class="item">
              <span class="label">Cor</span>
              <span class="item-value">Branco</span>
            </div>
            <div class="item">
              <span class="label">Lotação</span>
              <span class="item-value">5 pessoas</span>
            </div>
            <div class="item">
              <span class="label">Garantia</span>
              <span class="item-value">12 Meses</span>
            </div>
            <div class="item">
              <span class="label">Estado</span>
              <span class="item-value">Usado</span>
            </div>
            <div class="item">
              <span class="label">Portas</span>
              <span class="item-value">2</span>
            </div>
          </div>
        </div>
        <div v-if="windowWidth > 768" class="col-12">
          <Splide class="thumbs" :options="secondaryOptions" ref="secondary">
            <SplideSlide v-for="(image, index) in miniImages" :key="index">
              <img :src="image" alt="Veículo">
            </SplideSlide>
          </Splide>
        </div>
      </div>
      <div class="row more-details">
        <div class="col-12 col-lg-7">
          <div class="header">
            <div class="title">
              <img src="../assets/images/icons/details.svg" alt="Detalhes">
              <span>Mais detalhes</span>
            </div>
            <div class="line"></div>
          </div>
          <div class="list">
            <h3>Equipamento</h3>
            <div class="sub-list">
              <span>Faróis</span>
              <ul class="list-unstyled">
                <li>
                  <img src="../assets/images/icons/circle-check.svg" alt="Check">
                  <span>Faróis com Sensor Luminosidade</span>
                </li>
                <li>
                  <img src="../assets/images/icons/circle-check.svg" alt="Check">
                  <span>Faróis Diurnos</span>
                </li>
                <li>
                  <img src="../assets/images/icons/circle-check.svg" alt="Check">
                  <span>Faróis Dianteiros LED</span>
                </li>
              </ul>
            </div>

            <div class="sub-list">
              <span>Retrovisores</span>
              <ul class="list-unstyled">
                <li>
                  <img src="../assets/images/icons/circle-check.svg" alt="Check">
                  <span>Retrovisores com Regulagem Eléctrica</span>
                </li>
                <li>
                  <img src="../assets/images/icons/circle-check.svg" alt="Check">
                  <span>Retrovisores com anti-encandeamento</span>
                </li>
              </ul>
            </div>

            <div class="sub-list">
              <span>Bancos</span>
              <ul class="list-unstyled">
                <li>
                  <img src="../assets/images/icons/circle-check.svg" alt="Check">
                  <span>Apoio de Braço</span>
                </li>
                <li>
                  <img src="../assets/images/icons/circle-check.svg" alt="Check">
                  <span>Bancos dianteiros aquecidos</span>
                </li>
                <li>
                  <img src="../assets/images/icons/circle-check.svg" alt="Check">
                  <span>Bancos Dianteiros c/ Memória</span>
                </li>
                <li>
                  <img src="../assets/images/icons/circle-check.svg" alt="Check">
                  <span>Encostos de Cabeça Traseiros</span>
                </li>
                <li>
                  <img src="../assets/images/icons/circle-check.svg" alt="Check">
                  <span>Bancos com Apoio Lombar</span>
                </li>
                <li>
                  <img src="../assets/images/icons/circle-check.svg" alt="Check">
                  <span>Bancos Dianteiros c/ Regulagem Electrica</span>
                </li>
              </ul>
            </div>

            <div class="sub-list">
              <span>Segurança & Desempenho</span>
              <ul class="list-unstyled">
                <li>
                  <img src="../assets/images/icons/circle-check.svg" alt="Check">
                  <span>ISOFIX</span>
                </li>
                <li>
                  <img src="../assets/images/icons/circle-check.svg" alt="Check">
                  <span>Imobilizador</span>
                </li>
                <li>
                  <img src="../assets/images/icons/circle-check.svg" alt="Check">
                  <span>ABS</span>
                </li>
                <li>
                  <img src="../assets/images/icons/circle-check.svg" alt="Check">
                  <span>Direção assistida</span>
                </li>
                <li>
                  <img src="../assets/images/icons/circle-check.svg" alt="Check">
                  <span>Suspensão Desportiva</span>
                </li>
                <li>
                  <img src="../assets/images/icons/circle-check.svg" alt="Check">
                  <span>EDS Bloqueio Electrónico do Diferencial</span>
                </li>
                <li>
                  <img src="../assets/images/icons/circle-check.svg" alt="Check">
                  <span>Chave inteligente</span>
                </li>
                <li>
                  <img src="../assets/images/icons/circle-check.svg" alt="Check">
                  <span>Controle de pressão dos Pneus</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="observation">
            <h3>Observações</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore
              magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
              commodo
              consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
              pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim
              id </p>
          </div>
        </div>
        <div class="col-12 col-lg-5 ps-lg-5 col-right">
          <h4>Localização e contatos do veículo</h4>
          <div class="contact-item">
            <img src="../assets/images/icons/map-point.svg" alt="Map">
            <p>Avenida São Salvador 404 R/C - <br>EN1 4415-557 - Vila Nova de Gaia</p>
          </div>
          <div class="contact-item">
            <img src="../assets/images/icons/phone-2.svg" alt="Telefone">
            <p>951 256 985</p>
          </div>
          <div class="contact-item">
            <img src="../assets/images/icons/envelop.svg" alt="Envelop">
            <p>User@gmail.com</p>
          </div>
          <div ref="getInformationForm" class="form-info">
            <h4>Dúvidas ou pedidos de informações</h4>
            <form action="">
              <div class="form-group">
                <input ref="inputName" class="form-control" placeholder="Nome" type="text" name="form_info_name"
                       id="form_info_name">
              </div>
              <div class="form-group">
                <input class="form-control" placeholder="E-mail" type="email" name="form_info_email"
                       id="form_info_email">
              </div>
              <div class="form-group">
              <textarea class="form-control" placeholder="Mensagem" name="form_info_message" id="form_info_message"
                        rows="10"></textarea>
              </div>
              <span class="small">Ao enviar este formulário está nos dando autorização para utilização dos seus dados
                pessoais e
                concordando
                com a nossa Política Privacidade.
              </span>
              <button type="submit" class="btn btn-primary">Enviar</button>
            </form>
          </div>
        </div>
      </div>
      <div class="similar-vehicles">
        <h3>Poderá ter interessar em:</h3>
        <VehiclesGrid :vehicles="similarVehicles"/>
      </div>
    </div>
  </div>
</template>

<script>
import {SplideSlide, Splide} from '@splidejs/vue-splide';
import VehiclesGrid from "@/components/VehiclesGrid";
import FsLightbox from "fslightbox-vue";

export default {
  name: "Details",
  components: {
    VehiclesGrid,
    SplideSlide,
    Splide,
    FsLightbox
  },
  methods: {
    scrollToForm() {
      let InformationForm = this.$refs.getInformationForm;
      window.scroll(0, InformationForm.offsetTop - 100);
    },
    openLightboxOnSlide: function (number) {
      this.slide = number;
      this.toggler = !this.toggler;
    }
  },
  data() {
    return {
      windowWidth: window.outerWidth,
      toggler: false,
      slide: 1,
      bigImages: [
        require("../assets/images/vehicles/details/cover.png"),
        require("../assets/images/vehicles/details/cover.png"),
        require("../assets/images/vehicles/details/cover.png"),
        require("../assets/images/vehicles/details/cover.png"),
        require("../assets/images/vehicles/details/cover.png"),
        require("../assets/images/vehicles/details/cover.png"),
      ],
      miniImages: [
        require("../assets/images/vehicles/details/mini.png"),
        require("../assets/images/vehicles/details/mini.png"),
        require("../assets/images/vehicles/details/mini.png"),
        require("../assets/images/vehicles/details/mini.png"),
        require("../assets/images/vehicles/details/mini.png"),
        require("../assets/images/vehicles/details/mini.png"),
        require("../assets/images/vehicles/details/mini.png"),
        require("../assets/images/vehicles/details/mini.png"),
      ],
      similarVehicles: [
        {
          urlCover: require("../assets/images/vehicles/mercedes.png"),
          images: [
            require("../assets/images/vehicles/mercedes.png"),
            require("../assets/images/vehicles/honda.png"),
            require("../assets/images/vehicles/bmw.png"),
          ],
          title: "Mercedes-Benz R 320",
          version: "Cdi 4-Matic Longo",
          price: "R$ 24.900",
          registerYear: "18/19",
          fuel: "Gasolina",
          kilometers: "10.000Km",
          url: {
            name: 'singleVehicle',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        },
        {
          urlCover: require("../assets/images/vehicles/honda.png"),
          images: [
            require("../assets/images/vehicles/honda.png"),
            require("../assets/images/vehicles/mercedes.png"),
            require("../assets/images/vehicles/bmw.png"),
          ],
          title: "Honda E",
          version: "100% elétrico",
          price: "R$ 24.900",
          registerYear: "18/19",
          fuel: "Elétrico",
          kilometers: "10.000Km",
          url: {
            name: 'singleVehicle',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        },
        {
          urlCover: require("../assets/images/vehicles/bmw.png"),
          images: [
            require("../assets/images/vehicles/bmw.png"),
            require("../assets/images/vehicles/mini.png"),
            require("../assets/images/vehicles/bmw.png"),
          ],
          title: "Bmw m4",
          version: "3.2cc ",
          price: "R$ 24.900",
          registerYear: "18/19",
          fuel: "Gasolina",
          kilometers: "10.000Km",
          url: {
            name: 'singleVehicle',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        },
        {
          urlCover: require("../assets/images/vehicles/mini.png"),
          images: [
            require("../assets/images/vehicles/mini.png"),
            require("../assets/images/vehicles/bmw.png"),
            require("../assets/images/vehicles/bmw.png"),
          ],
          title: "Bmw m4",
          version: "3.2cc ",
          price: "R$ 24.900",
          registerYear: "18/19",
          fuel: "Gasolina",
          kilometers: "10.000Km",
          url: {
            name: 'singleVehicle',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        },
      ],
      primaryOptions: {
        type: 'loop',
        pagination: false,
        cover: true,
        breakpoints: {
          768: {
            height: 180,
          },
          1280: {
            height: 470,
          }
        }
      },
      secondaryOptions: {
        type: 'slide',
        rewind: true,
        arrows: false,
        gap: '0.6rem',
        pagination: false,
        fixedWidth: 192,
        fixedHeight: 122,
        cover: true,
        focus: 'center',
        isNavigation: true,
      },
    };
  },
  mounted() {
    let open = this.openLightboxOnSlide;
    let slides = document.getElementsByClassName("splide__slide");
    slides.forEach(function (el) {
      el.addEventListener("click", function () {
        let img = el.querySelector('img')
        let index = img.getAttribute('data-index');
        open(parseInt(index));
      });
    })

    if (this.windowWidth > 678) {
      this.$refs.primary.sync(this.$refs.secondary.splide)
    }
  },
}
</script>

<style scoped lang="scss">
.component-content {
  > .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
    margin-top: 30px;

    @media (min-width: 768px) {
      margin-top: 130px;
      width: auto;
      flex-direction: row;
    }

    .title {
      width: 100%;

      @media (min-width: 768px) {
        width: auto;
      }

      h1 {
        font-weight: 600;
        color: #272727;
        margin-bottom: 0px;
        font-size: 30px;
        line-height: 33px;

        @media (min-width: 768px) {
          line-height: 44px;
          font-size: 35px;
        }
      }

      p {
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #333333;
        margin-bottom: 6px;
      }

      .brand-logo {
        img {
          height: 34px;
        }
      }
    }

    .price {
      width: 100%;
      margin-top: 10px;

      @media (min-width: 768px) {
        margin-top: 0px;
        text-align: right;
        width: auto;
      }

      h2 {
        font-weight: 600;
        font-size: 35px;
        line-height: 44px;
        color: #272727;
        margin-bottom: 0px;
      }

      p {
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #333333;
      }

      ul {
        display: flex;
        flex-direction: row;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;

        @media (min-width: 768px) {
          justify-content: flex-end;
          width: auto;
        }

        li {
          width: 49%;
          margin-bottom: 15px;
          @media (min-width: 768px) {
            margin-bottom: 0px;
            width: auto;
          }

          &.get-info {
            width: 100%;

            .btn {
              height: 51px;
              width: 100%;
            }

            @media (min-width: 768px) {
              width: auto;

              .btn {
                width: auto;
              }
            }
          }
        }

        .btn {
          height: 46px;
          padding: 14px;
          background: #FFFFFF;
          box-shadow: 0px 5px 11px rgba(86, 86, 86, 0.1);
          border-radius: 3px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          transition: all ease-in-out 120ms;

          @media (min-width: 768px) {
            justify-content: space-between;
            margin-left: 16px;
          }

          &:hover {
            transition: all ease-in-out 120ms;
            background-color: #333333;
            color: #FFFFFF;

            img {
              filter: brightness(100);
            }
          }

          img {
            margin-right: 11px;
          }

          &.btn-primary {
            border: none;
            border-radius: 0;
            background-color: #DCA66A;

            &:hover {
              background-color: #333333;
              color: #FFFFFF;
            }

            img {
              filter: brightness(100);
              margin-right: 11px;
            }
          }
        }
      }
    }
  }

  .main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (min-width: 768px) {
      margin-top: 50px;
    }

    .thumbs {
      margin-top: 10px;
    }

    .top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 54px;

      h3 {
        font-weight: bold;
        font-size: 19px;
        display: flex;
        align-items: center;
        color: #272727;
        margin: 0;
      }

      .line {
        width: 70%;
        height: 0px;
        border-top: 1px solid #D0D4D7;
      }
    }

    .info-grid {
      display: grid;
      gap: 50px 65px;
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
      position: relative;

      .item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .label {
          font-weight: normal;
          font-size: 14px;
          line-height: 18px;
          color: #333333;
        }

        .item-value {
          margin-top: 5px;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: #333333;
        }
      }
    }
  }

  .more-details {
    margin-top: 80px;

    .header {
      margin-top: 0px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        img {
          margin-right: 10px;
        }

        span {
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          color: #363B3F;
        }
      }

      .line {
        border-top: 1px solid #D0D4D7;
        margin-left: 30px;
        width: 57%;

        @media (min-width: 768px) {
          width: 60%;
        }

      }
    }

    .list {
      margin-top: 40px;

      h3 {
        margin-bottom: 24px;
        font-weight: 600;
        font-size: 19px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #272727;
      }

      .sub-list {
        > span {
          font-weight: 600;
          font-size: 15px;
          line-height: 19px;
          display: flex;
          align-items: center;
          color: #363B3F;
          margin-bottom: 16px;
        }

        ul {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          li {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-right: 25px;
            margin-bottom: 16px;

            span {
              margin-left: 5px;
              font-weight: normal;
              font-size: 14px;
              line-height: 18px;
              color: #333333;
            }
          }
        }
      }
    }

    .observation {
      margin-bottom: 72px;
      width: 85%;

      &:before {
        content: "";
        height: 0px;
        width: 100%;
        display: block;
        border-top: 1px solid #D0D4D7;
      }

      &:after {
        content: "";
        height: 0px;
        width: 100%;
        display: block;
        border-top: 1px solid #D0D4D7;
      }

      h3 {
        margin-top: 40px;
        font-weight: 600;
        font-size: 19px;
        line-height: 24px;
        color: #272727;
        margin-bottom: 24px;
      }

      p {
        margin-bottom: 40px;
        font-weight: normal;
        font-size: 15px;
        line-height: 19px;
        color: #333333;
      }
    }

    .col-right {
      > h4 {
        font-weight: 600;
        font-size: 15px;
        line-height: 19px;
        color: #363B3F;
      }

      .contact-item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid #D0D4D7;
        min-height: 50px;

        img {
          margin-right: 15px;
        }

        p {
          margin: 0;
          font-weight: normal;
          font-size: 14px;
          line-height: 18px;
          color: #333333;
        }
      }

      .form-info {
        h4 {
          margin-top: 25px;
          margin-bottom: 24px;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          color: #333333;
        }

        form {
          .form-control {
            height: 49px;
            background: #FFFFFF;
            border: 1px solid #E7E7E7;
            box-sizing: border-box;
            border-radius: 3px;
            margin-bottom: 17px;
          }

          textarea.form-control {
            height: 120px;
            margin-bottom: 0px;
          }

          .btn {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 15px 27px;
            width: 101px;
            height: 50px;
            background: #DCA66A;
            box-shadow: 0px 5px 11px rgba(86, 86, 86, 0.1);
            border-radius: 3px;
            float: right;
            margin-top: 5px;
            font-weight: normal;
            font-size: 16px;
          }
        }
      }
    }

  }

  .similar-vehicles {
    margin-top: 50px;
    margin-bottom: 65px;

    h3 {
      font-weight: 600;
      font-size: 19px;
      line-height: 24px;
      color: #272727;
      margin-bottom: 24px;
    }
  }

  .small {
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    text-align: right;
    margin-bottom: 10px;
    color: #888888;
    display: inline-block;
  }
}
</style>
