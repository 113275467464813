<template>
  <hooper :settings="hooperSettings">
    <slide v-for="(image, index) in images" :key="index" :index="index">
      <img :src="`${image}`" alt="Imagem Veículo">
    </slide>
    <hooper-pagination slot="hooper-addons"></hooper-pagination>
  </hooper>
</template>

<script>
import {Hooper, Slide, Pagination as HooperPagination} from "hooper";

export default {
  name: "SlideVehicleImages",
  props: {
    images: Array
  },
  components: {
    Hooper,
    Slide,
    HooperPagination
  },
  data() {
    return {
      hooperSettings: {
        autoPlay: true,
        infiniteScroll: true,
        playSpeed: 1000,
        hoverPause: false,
        wheelControl: false,
        height: 'auto'
      }
    }
  },
}
</script>

<style scoped lang="scss">
a {
  display: block;
  height: 100%;
  width: 100%;

  img {
    width: 100%;
  }
}
</style>

<style>
.hooper {
  height: auto !important;
}
</style>
