<template>
  <div class="component-content container">
    <div class="row">
      <div class="col-12 col-lg-5">
        <h1>Serviço de oficinas</h1>
        <p>Para sua maior comodidade, e como complemento do pós-venda, dispomos ainda de oficina, onde poderá
          encontrar os
          seguintes serviços, entre outros:</p>
      </div>
    </div>
    <div class="row main-row">
      <div class="col-12 col-lg-5">
        <div class="grid">
          <div class="block">
            <h3>Mecânica</h3>
            <ul class="list-unstyled">
              <li>
                <img src="../assets/images/icons/arrow-item.svg" alt="Item">
                <span>Revisões completas</span>
              </li>
              <li>
                <img src="../assets/images/icons/arrow-item.svg" alt="Item">
                <span>Troca de óleo</span>
              </li>
              <li>
                <img src="../assets/images/icons/arrow-item.svg" alt="Item">
                <span>Troca de filtros</span>
              </li>
              <li>
                <img src="../assets/images/icons/arrow-item.svg" alt="Item">
                <span>Verificação de freios</span>
              </li>
              <li>
                <img src="../assets/images/icons/arrow-item.svg" alt="Item">
                <span>Inspeção</span>
              </li>
            </ul>
          </div>
          <div class="block">
            <h3>Pintura</h3>
            <ul class="list-unstyled">
              <li>
                <img src="../assets/images/icons/arrow-item.svg" alt="Item">
                <span>Pintura completa</span>
              </li>
              <li>
                <img src="../assets/images/icons/arrow-item.svg" alt="Item">
                <span>Pintura parcial</span>
              </li>
              <li>
                <img src="../assets/images/icons/arrow-item.svg" alt="Item">
                <span>Polimento</span>
              </li>
              <li>
                <img src="../assets/images/icons/arrow-item.svg" alt="Item">
                <span>Recondicionamento</span>
              </li>
              <li>
                <img src="../assets/images/icons/arrow-item.svg" alt="Item">
                <span>Serviços rápidos</span>
              </li>
            </ul>
          </div>
          <div class="block">
            <h3>Lavagem</h3>
            <ul class="list-unstyled">
              <li>
                <img src="../assets/images/icons/arrow-item.svg" alt="Item">
                <span>Lavagem externa</span>
              </li>
              <li>
                <img src="../assets/images/icons/arrow-item.svg" alt="Item">
                <span>Lavagem de interiores</span>
              </li>
              <li>
                <img src="../assets/images/icons/arrow-item.svg" alt="Item">
                <span>Limpeza de motor</span>
              </li>
              <li>
                <img src="../assets/images/icons/arrow-item.svg" alt="Item">
                <span>Aplicação de cera</span>
              </li>
              <li>
                <img src="../assets/images/icons/arrow-item.svg" alt="Item">
                <span>Polimento final</span>
              </li>
            </ul>
          </div>
          <div class="block">
            <h3>Pós-Venda</h3>
            <ul class="list-unstyled posvenda">
              <li>
                <img src="../assets/images/icons/arrow-item.svg" alt="Item">
                <span>Garantia Total</span>
              </li>
              <li>
                <img src="../assets/images/icons/arrow-item.svg" alt="Item">
                <span>Veículo de substituição</span>
              </li>
              <li>
                <img src="../assets/images/icons/arrow-item.svg" alt="Item">
                <span>Mecânicos especializados</span>
              </li>
              <li>
                <img src="../assets/images/icons/arrow-item.svg" alt="Item">
                <span>Acompanhamento contínuo</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-5">
        <img class="img-fluid" src="../assets/images/service-image.png" alt="Serviços">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Services"
}
</script>

<style scoped lang="scss">
.component-content {

  &.container {
    margin-top: 130px;
  }

  h1 {
    font-style: normal;
    font-weight: 600;
    margin-bottom: 16px;
    color: #272727;
    font-size: 30px;
    line-height: 33px;


    @media (min-width: 768px) {
      margin-bottom: 36px;
      font-size: 35px;
      line-height: 44px;
    }
  }

  p {
    margin-top: 17px;
    margin-bottom: 65px;
    font-weight: normal;
    line-height: 19px;
    color: #333333;
    font-size: 18px;

    @media (min-width: 768px) {
      font-size: 15px;
    }
  }

  .main-row {
    display: flex;
    flex-direction: column-reverse;

    @media (min-width: 768px) {
      flex-direction: row;
    }

    .grid {
      display: grid;
      gap: 30px 19px;
      position: relative;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

      @media (min-width: 1920px) {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      }

      h3 {
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
        color: #272727;
        margin-bottom: 12px;
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        @media (min-width: 768px) {
          flex-wrap: nowrap;
          flex-direction: column;
        }

        &.posvenda {
          li {
            width: 100%;
          }
        }

        li {
          width: 50%;

          @media (min-width: 768px) {
            width: 100%;
          }

          img {
            margin-right: 6px;
          }

          span {
            font-weight: normal;
            font-size: 15px;
            line-height: 30px;
            color: #333333;
          }
        }
      }
    }

    .img-fluid {
      margin-bottom: 50px;

      @media (min-width: 768px) {
        margin-bottom: 184px;
      }
    }
  }
}
</style>
