<template>
  <div v-if="type === 'list'" class="vehicles-list">
    <VehicleItemList v-for="vehicle in vehicles" :key="vehicle.id" :vehicle="vehicle"></VehicleItemList>
  </div>
  <div v-else class="vehicles-list">
    <Vehicle v-for="vehicle in vehicles" :key="vehicle.id" :vehicle="vehicle"></Vehicle>
  </div>
</template>

<script>
import Vehicle from "@/components/Vehicle";
import VehicleItemList from "./VehicleItemList";

export default {
  name: "VehiclesGrid",
  components: {VehicleItemList, Vehicle},
  props: {
    type: String,
    vehicles: Array
  },
}
</script>

<style scoped lang="scss">
.vehicles-list {
  display: grid;
  position: relative;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px 0px;

  @media (min-width: 768px) {
    gap: 30px 19px;
  }

  @media (min-width: 1300px) {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  }
}
</style>
