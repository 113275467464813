<template>
  <div class="card">
    <div class="card-img-top">
      <router-link :to="post.url">
        <img :src="`${post.urlCover}`" :alt="post.tag">
      </router-link>
    </div>
    <router-link :to="post.url" class="card-body">
      <p>{{ post.title }}</p>
      <div class="line-info">
        <div class="tag">{{ post.tag }}</div>
        <div class="date">
          <img src="../assets/images/icons/calendar.svg" alt="Calendário">
          <span>{{ post.date }}</span>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "BlogCard",
  props: {
    post: {
      id: Number,
      urlCover: String,
      title: String,
      date: String,
      tag: String,
      url: String,
    }
  },
}
</script>

<style scoped lang="scss">
.card {
  border: none;
  background-color: transparent;
  display: flex;
  flex-direction: row-reverse;
  height: 130px;

  @media (min-width: 768px) {
    height: auto;
    flex-direction: column;
  }

  &:hover {
    .card-body {
      p {
        text-decoration: underline;
      }
    }
  }

  .card-img-top {
    img {
      height: 100%;
      max-width: 100%;
      width: 100%;
      object-fit: cover;
    }

    @media (min-width: 768px) {
      height: auto;
    }
  }

  .card-body {
    text-decoration: none;
    padding: 0px;

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      color: #333333;
      margin-bottom: 30px;
      height: 60px;
      overflow: hidden;
      padding-right: 10px;

      @media (min-width: 768px) {
        padding-right: 0px;
        margin-top: 16px;
        height: auto;
      }
    }

    .line-info {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;

      .tag {
        width: 110px;
        height: 33px;
        background: #333333;
        font-weight: normal;
        font-size: 15px;
        line-height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
      }

      .date {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 87px;
        align-items: center;
        color: #333333;
        margin-left: 10px;

        @media (min-width: 768px) {
          margin-left: 22px;
        }

        img {
          margin-right: 0px;

          @media (min-width: 768px) {
            margin-right: 6px;
          }
        }
      }
    }
  }
}
</style>
