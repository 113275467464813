<template>
  <div class="paginator">
    <button class="arrows">
      <img src="../assets/images/icons/arrow-left-paginator.svg" alt="Less">
    </button>
    <div class="page-links">
      <div class="first">
        <router-link class="active" to="/">1</router-link>
        <router-link to="/">2</router-link>
        <router-link to="/">3</router-link>
        <router-link to="/">4</router-link>
      </div>
      <div class="last">
        <span>.</span>
        <span>.</span>
        <span>.</span>
        <router-link to="/">50</router-link>
      </div>
    </div>
    <button class="arrows">
      <img src="../assets/images/icons/arrow-right-paginator.svg" alt="More">
    </button>
  </div>
</template>

<script>
export default {
  name: "Paginator"
}
</script>

<style scoped lang="scss">
.paginator {
  margin: 54px auto 104px;
  width: 340px;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .arrows {
    transition: all ease-in-out 100ms;
    border: none;
    background: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 31px;
    height: 31px;
    border-radius: 3px;

    &:hover {
      transition: all ease-in-out 100ms;
      background-color: #DCA66A;
      color: #FFFFFF;

      img {
        filter: brightness(100);
      }
    }
  }

  .page-links {
    width: 250px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .first {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 65%;
    }

    .last {
      width: 30%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;

      span {
        padding-bottom: 4px;
      }
    }

    a {
      border: none;
      text-decoration: none;
      width: 31px;
      height: 31px;
      font-size: 16px;
      text-align: center;
      color: #363B3F;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px !important;

      &.active {
        background-color: #DCA66A;
        color: #FFFFFF;
      }

      &:hover {
        transition: all ease-in-out 100ms;
        background-color: #DCA66A;
        color: #FFFFFF;
      }
    }
  }
}
</style>
