<template>
  <ul class="list-unstyled social-links">
    <li>
      <a href="" class="social-button">
        <img src="../assets/images/icons/instagram.svg" alt="instagram">
      </a>
    </li>
    <li>
      <a href="" class="social-button">
        <img src="../assets/images/icons/whatsapp.svg" alt="whatsapp">
      </a>
    </li>
    <li>
      <a href="" class="social-button">
        <img src="../assets/images/icons/twitter.svg" alt="twitter">
      </a>
    </li>
    <li>
      <a href="" class="social-button">
        <img src="../assets/images/icons/youtube.svg" alt="youtube">
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "SocialButtons"
}
</script>

<style scoped lang="scss">
.social-links {
  width: 180px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 0px;

  .social-button {
    transition: all ease-in-out 100ms;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-color: #313134;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      transition: all ease-in-out 100ms;
      background-color: #DCA66A;
    }

    img {
      width: 20px;
    }
  }
}
</style>
