<template>
  <form action="!#" class="filter">
    <div class="inputs-list">
      <v-select append-to-body placeholder="Marca" :options="brands" class="custom-select"
                name="filter_brand"
                id="filter_brand"></v-select>
      <v-select append-to-body placeholder="Modelo" :options="models" class="custom-select"
                name="filter_model"
                id="filter_model"></v-select>
      <v-select append-to-body placeholder="Ano" :options="years" class="custom-select"
                name="filter_year"
                id="filter_year"></v-select>
      <v-select append-to-body placeholder="Versão" :options="versions"
                class="custom-select"
                name="filter_category"
                id="filter_category"></v-select>
      <v-select append-to-body placeholder="Combustível" :options="fuels" class="custom-select"
                name="filter_fuel"
                id="filter_fuel"></v-select>
      <div class="together">
        <v-select append-to-body placeholder="Km de" :options="kms" class="custom-select"
                  name="filter_kms_from"
                  id="filter_kms_from"></v-select>
        <v-select append-to-body placeholder="Km até" :options="kms" class="custom-select"
                  name="filter_kms_to"
                  id="filter_kms_to"></v-select>
      </div>
      <div v-if="windowWidth > 768" class="together">
        <VueSimpleRangeSlider
            style="width: 100%"
            :min="1000"
            activeBarColor="#DCA66A"
            :max="100000"
            v-model="range"
        />
      </div>
      <div v-else class="together">
        <v-select append-to-body placeholder="Preço de" :options="prices" class="custom-select"
                  name="filter_price_from"
                  id="filter_price_from"></v-select>
        <v-select append-to-body placeholder="Preço até" :options="prices" class="custom-select"
                  name="filter_price_to"
                  id="filter_price_to"></v-select>
      </div>
      <button type="submit" class="btn btn-primary">
        <img src="../assets/images/icons/search.svg" alt="Search">
        <span>Pesquisar</span>
      </button>
    </div>
  </form>
</template>

<script>
import VueSimpleRangeSlider from "vue-simple-range-slider";

export default {
  name: "FilterInputsList",
  components: {VueSimpleRangeSlider},
  data() {
    return {
      windowWidth: window.outerWidth,
      range: [5000, 60000],
      number: 10,
      versions: [
        "EXS 4P auto",
        "I34 4p manual",
        "92AA 2p manual"
      ],
      brands: [
        "Ford",
        "Audi",
        "BMW",
        "Chevrolet",
        "Volvo",
        "Yamaha",
        "Renault",
        "Porsche  ",
        "Volkswagen  ",
      ],
      models: [
        "Fiesta",
        "Focus",
        "Furglaine",
        "Fusion",
        "Ka",
      ],
      gearboxes: [
        "Automático",
        "Manual",
        "Automatizado",
      ],
      years: [
        "2010",
        "2015",
        "2020",
      ],
      prices: [
        "R$10.000,00",
        "R$20.000,00",
        "R$30.000,00"
      ],
      kms: [
        "100000",
        "200000",
        "300000",
        "400000",
        "500000",
        "600000",
      ],
      fuels: [
        "Gasolina",
        "Diesel",
        "Etanol",
        "Elétrico",
        "Híbrido",
      ],
    }
  }
}
</script>

<style scoped lang="scss">
.filter {
  display: flex;
  justify-content: space-between;
  align-content: center;
  justify-items: center;
  width: 100%;
  flex-direction: column;

  .inputs-list {
    display: flex;
    justify-content: space-between;
    align-content: center;
    justify-items: center;
    width: 100%;
    flex-wrap: wrap;

    .custom-select {
      height: 54px;
      width: 100%;

      @media (min-width: 768px) {
        width: calc(94% / 5);
      }
    }

    .together {
      display: flex;
      width: 100%;

      @media (min-width: 768px) {
        width: calc((94% / 5) * 2 + 1.5%);
      }

      .custom-select {
        width: 50%;
      }
    }

    .btn {
      border-radius: 3px;
      font-size: 16px;
      line-height: 27px;
      text-align: center;
      color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      width: 100%;

      @media (min-width: 768px) {
        width: calc(94% / 5);
      }

      img {
        margin-right: 10px;
      }
    }
  }
}
</style>
