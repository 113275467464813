<template>
  <div class="page-content">
    <h1 class="invisible position-absolute">MyStand.com.br - Site para loja de carro em São Paulo</h1>
    <HeaderHome/>
    <div class="container">
      <VehiclesGrid :vehicles="windowWidth > 600 ? vehicles : vehiclesMobile"/>
      <router-link class="link-see-all" to="veiculos">Ver todos (56)</router-link>
      <LastNews/>
    </div>
    <Testimonials/>
  </div>
</template>

<script>
import HeaderHome from "@/components/HeaderHome";
import LastNews from "@/components/LastNews";

import Testimonials from "@/components/Testimonials";
import VehiclesGrid from "@/components/VehiclesGrid";

export default {
  name: 'Home',
  components: {VehiclesGrid, Testimonials, LastNews, HeaderHome},
  data() {
    return {
      windowWidth: window.outerWidth,
      vehiclesMobile: [
        {
          urlCover: require("../assets/images/vehicles/mercedes.png"),
          images: [
            require("../assets/images/vehicles/mercedes.png"),
            require("../assets/images/vehicles/honda.png"),
            require("../assets/images/vehicles/bmw.png"),
          ],
          title: "Mercedes-Benz R 320",
          version: "Cdi 4-Matic Longo",
          price: "R$ 24.900",
          registerYear: "18/19",
          fuel: "Gasolina",
          kilometers: "10.000Km",
          url: {
            name: 'singleVehicle',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        },
        {
          urlCover: require("../assets/images/vehicles/honda.png"),
          images: [
            require("../assets/images/vehicles/honda.png"),
            require("../assets/images/vehicles/mercedes.png"),
            require("../assets/images/vehicles/bmw.png"),
          ],
          title: "Honda E",
          version: "100% elétrico",
          price: "R$ 24.900",
          registerYear: "18/19",
          fuel: "Elétrico",
          kilometers: "10.000Km",
          url: {
            name: 'singleVehicle',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        },
        {
          urlCover: require("../assets/images/vehicles/bmw.png"),
          images: [
            require("../assets/images/vehicles/bmw.png"),
            require("../assets/images/vehicles/mini.png"),
            require("../assets/images/vehicles/bmw.png"),
          ],
          title: "Bmw m4",
          version: "3.2cc ",
          price: "R$ 24.900",
          registerYear: "18/19",
          fuel: "Gasolina",
          kilometers: "10.000Km",
          url: {
            name: 'singleVehicle',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        },
        {
          urlCover: require("../assets/images/vehicles/mini.png"),
          images: [
            require("../assets/images/vehicles/mini.png"),
            require("../assets/images/vehicles/bmw.png"),
            require("../assets/images/vehicles/bmw.png"),
          ],
          title: "Bmw m4",
          version: "3.2cc ",
          price: "R$ 24.900",
          registerYear: "18/19",
          fuel: "Gasolina",
          kilometers: "10.000Km",
          url: {
            name: 'singleVehicle',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        }
      ],
      vehicles: [
        {
          urlCover: require("../assets/images/vehicles/mercedes.png"),
          images: [
            require("../assets/images/vehicles/mercedes.png"),
            require("../assets/images/vehicles/honda.png"),
            require("../assets/images/vehicles/bmw.png"),
          ],
          title: "Mercedes-Benz R 320",
          version: "Cdi 4-Matic Longo",
          price: "R$ 24.900",
          registerYear: "18/19",
          fuel: "Gasolina",
          kilometers: "10.000Km",
          url: {
            name: 'singleVehicle',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        },
        {
          urlCover: require("../assets/images/vehicles/honda.png"),
          images: [
            require("../assets/images/vehicles/honda.png"),
            require("../assets/images/vehicles/mercedes.png"),
            require("../assets/images/vehicles/bmw.png"),
          ],
          title: "Honda E",
          version: "100% elétrico",
          price: "R$ 24.900",
          registerYear: "18/19",
          fuel: "Elétrico",
          kilometers: "10.000Km",
          url: {
            name: 'singleVehicle',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        },
        {
          urlCover: require("../assets/images/vehicles/bmw.png"),
          images: [
            require("../assets/images/vehicles/bmw.png"),
            require("../assets/images/vehicles/mini.png"),
            require("../assets/images/vehicles/bmw.png"),
          ],
          title: "Bmw m4",
          version: "3.2cc ",
          price: "R$ 24.900",
          registerYear: "18/19",
          fuel: "Gasolina",
          kilometers: "10.000Km",
          url: {
            name: 'singleVehicle',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        },
        {
          urlCover: require("../assets/images/vehicles/mini.png"),
          images: [
            require("../assets/images/vehicles/mini.png"),
            require("../assets/images/vehicles/bmw.png"),
            require("../assets/images/vehicles/bmw.png"),
          ],
          title: "Bmw m4",
          version: "3.2cc ",
          price: "R$ 24.900",
          registerYear: "18/19",
          fuel: "Gasolina",
          kilometers: "10.000Km",
          url: {
            name: 'singleVehicle',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        },
        {
          urlCover: require("../assets/images/vehicles/mercedes.png"),
          images: [
            require("../assets/images/vehicles/mercedes.png"),
            require("../assets/images/vehicles/honda.png"),
            require("../assets/images/vehicles/bmw.png"),
          ],
          title: "Mercedes-Benz R 320",
          version: "Cdi 4-Matic Longo",
          price: "R$ 24.900",
          registerYear: "18/19",
          fuel: "Gasolina",
          kilometers: "10.000Km",
          url: {
            name: 'singleVehicle',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        },
        {
          urlCover: require("../assets/images/vehicles/honda.png"),
          images: [
            require("../assets/images/vehicles/honda.png"),
            require("../assets/images/vehicles/mercedes.png"),
            require("../assets/images/vehicles/bmw.png"),
          ],
          title: "Honda E",
          version: "100% elétrico",
          price: "R$ 24.900",
          registerYear: "18/19",
          fuel: "Elétrico",
          kilometers: "10.000Km",
          url: {
            name: 'singleVehicle',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        },
        {
          urlCover: require("../assets/images/vehicles/bmw.png"),
          images: [
            require("../assets/images/vehicles/bmw.png"),
            require("../assets/images/vehicles/mini.png"),
            require("../assets/images/vehicles/bmw.png"),
          ],
          title: "Bmw m4",
          version: "3.2cc ",
          price: "R$ 24.900",
          registerYear: "18/19",
          fuel: "Gasolina",
          kilometers: "10.000Km",
          url: {
            name: 'singleVehicle',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        },
        {
          urlCover: require("../assets/images/vehicles/mini.png"),
          images: [
            require("../assets/images/vehicles/mini.png"),
            require("../assets/images/vehicles/bmw.png"),
            require("../assets/images/vehicles/bmw.png"),
          ],
          title: "Bmw m4",
          version: "3.2cc ",
          price: "R$ 24.900",
          registerYear: "18/19",
          fuel: "Gasolina",
          kilometers: "10.000Km",
          url: {
            name: 'singleVehicle',
            params: {
              id: Math.floor(Math.random() * 100)
            }
          },
        },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 124.68%;
  color: #272727;
  margin-bottom: 36px;
}

.link-see-all {
  width: 100%;
  height: 50px;
  background: #DCA66A;
  border-radius: 3px;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  margin-top: 40px;
  text-decoration: none;


  @media (min-width: 768px) {
    width: auto;
    height: auto;
    background: transparent;
    border-radius: 0px;
    text-decoration: underline;
    display: inline-block;
    text-align: right;
    color: #DCA66A;
    float: right;
    margin-top: 10px;
    font-size: 17px;
    line-height: 23px;
  }
}
</style>
