<template>
  <div class="component-content">
    <div class="full-background-image">
      <div class="header-content">
        <div class="left-content">
          <div class="brand-logo justify-content-center align-content-center">
            <img src="../assets/logo.svg" alt="MyStand">
          </div>
          <div class="h1">
            Nós ajudamos a encontrar o seu carro dos sonhos!
          </div>
          <div class="arrows d-none d-lg-flex">
            <button class="slide-button" v-on:click="toPreviousSlide">
              <img src="../assets/images/icons/arrow-left.svg" alt="Left">
            </button>
            <button class="slide-button" v-on:click="toNextSlide">
              <img src="../assets/images/icons/arrow-right.svg" alt="Right">
            </button>
          </div>
        </div>
        <div class="right-content d-none d-lg-flex">
          <div class="slide">
            <Splide @splide:mounted="onMounted" :options="options">
              <SlideTop v-for="slide in slides" :key="slide.id" :slide="slide"/>
            </Splide>
          </div>
        </div>
      </div>
    </div>
    <FilterBox/>
  </div>
</template>

<script>
import FilterBox from "@/components/FilterBox";
import SlideTop from "@/components/SlideTop";
import {Splide} from "@splidejs/vue-splide";
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export default {
  name: "HeaderHome",
  components: {
    SlideTop,
    Splide,
    FilterBox
  },
  data() {
    return {
      splide: null,
      slides: [
        {
          imageUrl: require("@/assets/images/slide-home-image-1.png"),
          title: "BMW 420d - 2.0d",
          price: "R$ 20.000",
          url: "http://localhost:8000"
        },
        {
          imageUrl: require("@/assets/images/slide-home-image-2.png"),
          title: "BMW 420d - 2.0d",
          price: "R$ 20.000",
          url: "http://localhost:8000"
        },
        {
          imageUrl: require("@/assets/images/slide-home-image-1.png"),
          title: "BMW 420d - 2.0d",
          price: "R$ 20.000",
          url: "http://localhost:8000"
        },
        {
          imageUrl: require("@/assets/images/slide-home-image-2.png"),
          title: "BMW 420d - 2.0d",
          price: "R$ 20.000",
          url: "http://localhost:8000"
        },
        {
          imageUrl: require("@/assets/images/slide-home-image-1.png"),
          title: "BMW 420d - 2.0d",
          price: "R$ 20.000",
          url: "http://localhost:8000"
        },
        {
          imageUrl: require("@/assets/images/slide-home-image-2.png"),
          title: "BMW 420d - 2.0d",
          price: "R$ 20.000",
          url: "http://localhost:8000"
        },
      ],
      options: {
        pagination: false,
        drag: false,
        arrows: false,
        fixedHeight: '53vh',
        fixedWidth: '22vw',
        breakpoints: {
          1600: {
            cover: true,
            gap: 50,
            perPage: 2,
            padding: {
              right: 25,
            },
          },
          1920: {
            perPage: 2,
            padding: {
              right: 200,
            },
          },
        }
      }
    }
  },
  methods: {
    onMounted(splide) {
      this.splide = splide;
    },
    toNextSlide() {
      this.splide.go("+1")
    },
    toPreviousSlide() {
      this.splide.go("-1")
    }
  },
}
</script>

<style scoped lang="scss">
.component-content {

  .full-background-image {
    position: relative;
    background-image: url("../assets/images/home-background.png");
    background-size: cover;
    width: 100%;
    height: calc(100vh - 88px);
    padding-top: 15px;

    @media (min-width: 414px) {
      padding-top: 5vh;
    }

    @media (min-width: 1920px) {
      padding-top: 14vh;
    }

    .header-content {
      position: relative;
      height: 125px;

      @media (min-width: 768px) {
        height: 53vh;
      }

      .left-content {
        left: 0px;
        position: absolute;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        @media (min-width: 768px) {
          left: 1vw;
          justify-content: center;
          width: 28%;
        }

        @media (min-width: 1360px) {
          left: 4.4vw;
          width: 24%;
        }

        @media (min-width: 1920px) {
          width: 21%;
          left: 260px;
        }

        .brand-logo {
          display: none;
          width: 100%;

          img {
            height: 52px;
          }

          @media (min-width: 414px) {
            display: flex;
          }

          @media (min-width: 768px) {
            display: none;
          }
        }

        .h1 {
          font-style: normal;
          font-weight: 600;
          letter-spacing: 0em;
          color: #FFFFFF;
          text-align: center;
          font-size: 25px;
          line-height: 28px;
          padding: 2px 10px;

          @media (min-width: 768px) {
            padding: 0px;
            line-height: 40px;
            font-size: 35px;
            text-align: left;
          }

          @media (min-width: 1920px) {
            line-height: 53px;
            font-size: 48px;
          }
        }

        .arrows {
          position: absolute;
          bottom: 0;
          height: 60px;
          width: 140px;
          display: flex;
          justify-content: space-between;

          .slide-button {
            transition: all linear 100ms;
            height: 60px;
            width: 60px;
            background: rgba(255, 255, 255, 0.1);
            backdrop-filter: blur(16px);
            border-radius: 3px;
            border: 1px solid rgba(214, 214, 214, 0.4);
            padding: 0px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
              transition: all linear 100ms;
              background: #DCA66A;
              border: 1px solid rgba(214, 214, 214, 0.7);

              img {
                transition: all linear 100ms;
                filter: brightness(100);
              }
            }
          }
        }
      }

      .right-content {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .slide {
          width: 65vw;

          @media (min-width: 1920px) {
            width: 60vw;
          }

          .splide__slide {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            height: 100%;
          }
        }
      }
    }
  }
}

</style>
