<template>
  <div class="component-content container">
    <div class="fixed-top-items">
      <div class="top">
        <h3>Veja as nossas últimas notícias</h3>
        <router-link class="link-see-all" to="blog">Ver todas</router-link>
      </div>
    </div>
    <Splide @splide:mounted="onMounted" :options="options" @splide:moved="onMoved">
      <SplideSlide v-for="post in posts" :key="post.id">
        <SlidePost :post="post"></SlidePost>
      </SplideSlide>
    </Splide>
    <div class="fixed-bottom-items">
      <div class="controls">
        <div class="arrows">
          <button class="slide-button" v-on:click="toPreviousSlide">
            <img src="../assets/images/icons/arrow-left-black.svg" alt="Left">
          </button>
          <button class="slide-button" v-on:click="toNextSlide">
            <img src="../assets/images/icons/arrow-right-black.svg" alt="Right">
          </button>
        </div>
        <div class="counter">
          <span class="active">{{ current | zeroPad }}</span>
          <span>/</span>
          <span>{{ total | zeroPad }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Splide, SplideSlide} from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import SlidePost from "@/components/SlidePost";

export default {
  name: "LastNews",
  components: {
    SlidePost,
    Splide,
    SplideSlide,
  },
  filters: {
    zeroPad(num) {
      let zero = 2 - num.toString().length + 1;
      return Array(+(zero > 0 && zero)).join("0") + num;
    }
  },
  data() {
    return {
      total: 0,
      current: 1,
      splide: null,
      options: {
        type: "fade",
        pagination: false,
        arrows: false,
      },
      posts: [
        {
          id: 1,
          title: "Audi e-tron GT",
          date: "16/02/2021",
          text: "Com a versão mais potente chegando aos 600 cv e uma autonomia de 470 cv, o e-tron GT é apresentado como o Audi mais evoluído de sempre.",
          url: "http://demo.v1.mystand.com.br/post/1",
          imageUrl: require("../assets/images/news/image-news-1.png"),
        },
        {
          id: 2,
          title: "Mercedez Benz",
          date: "19/02/2021",
          text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium commodi dicta dolorem doloribus ducimus exercitationem illo ipsum iste iusto laboriosam.",
          url: "http://demo.v1.mystand.com.br/post/2",
          imageUrl: require("../assets/images/news/image-news-2.png"),
        },
        {
          id: 3,
          title: "Honda-E",
          date: "19/02/2021",
          text: "Com a versão mais potente consectetur adipisicing elit. Dolorem doloribus ducimus exercitationem illo ipsum iste iusto laboriosam.",
          url: "http://demo.v1.mystand.com.br/post/3",
          imageUrl: require("../assets/images/news/image-news-3.png"),
        },
      ],
    }
  },
  methods: {
    onMounted(splide) {
      this.splide = splide;
      this.total = this.posts.length;
    },
    onMoved(splide, next) {
      this.current = next + 1;
    },
    toNextSlide() {
      this.splide.go("+1")
    },
    toPreviousSlide() {
      this.splide.go("-1")
    }
  },
}
</script>

<style scoped lang="scss">
.component-content {
  padding: 0;
  margin-bottom: 155px;
  position: relative;
  height: 520px;
  margin-top: 205px;

  @media (min-width: 768px) {
    margin-top: 127px;
    height: 464px;
  }

  .fixed-top-items {
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: -150px;
    width: 100%;

    @media (min-width: 768px) {
      width: auto;
      top: 0;
    }

    .top {
      width: 80%;
      text-align: center;
      margin: 0 auto;

      @media (min-width: 768px) {
        width: auto;
        text-align: left;
      }

      h3 {
        font-weight: 600;
        line-height: 42px;
        color: #272727;
        font-size: 28px;
        width: 100%;
        text-align: center;

        @media (min-width: 768px) {
          font-size: 35px;
          width: auto;
          text-align: left;
        }
      }

      .link-see-all {
        transition: all linear 100ms;
        font-weight: 400;
        font-size: 17px;
        line-height: 23px;
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #333333;
        justify-content: center;

        @media (min-width: 768px) {
          justify-content: flex-start;
        }

        &:hover {
          transition: all linear 100ms;
          text-decoration: underline;
        }
      }
    }
  }

  .fixed-bottom-items {
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    bottom: 0;

    .controls {
      display: flex;

      @media (min-width: 768px) {
        padding: 24px 0px 24px 23px;
      }

      .arrows {
        height: 50px;
        width: 106px;
        display: flex;
        justify-content: space-between;

        .slide-button {
          transition: all linear 100ms;
          width: 48px;
          height: 48px;
          background: transparent;
          border-radius: 3px;
          border: 2px solid rgba(0, 0, 0, 0.9);
          padding: 0px;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            transition: all linear 100ms;
            background: rgba(0, 0, 0, 0.9);
            border: 2px solid rgba(0, 0, 0, 0.9);

            img {
              transition: all linear 100ms;
              filter: brightness(100);
            }
          }
        }
      }

      .counter {
        margin-left: 22px;
        display: flex;
        align-items: center;

        span {
          font-size: 25px;
          line-height: 25px;

          &.active {
            font-weight: 600;
          }
        }
      }
    }
  }
}
</style>
