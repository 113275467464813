import Vue from 'vue'
import App from './App.vue'
import "bootstrap"
import "./assets/fonts/fontawesome/css/all.css"
import "./scss/app.scss"
import vSelect from 'vue-select'
import router from "./router"
import ArrowDownSelect from "@/components/elements/ArrowDownSelect";
import VModal from 'vue-js-modal/dist/index.nocss'
import 'vue-js-modal/dist/styles.css'

vSelect.props.clearable = false;
vSelect.props.components.default = () => ({
    Deselect: {
        render: createElement => createElement('span', '❌'),
    },
    OpenIndicator: {
        render: createElement => createElement(ArrowDownSelect),
    },
})

Vue.use(VModal)
Vue.component('v-select', vSelect)

Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
