<template>
  <div class="component-content container">
    <h1>{{ post.title }}</h1>
    <Splide>
      <SplideSlide v-for="(image, index) in post.images" :key="index">
        <img class="img-fluid" :src="image" :alt="post.title">
      </SplideSlide>
    </Splide>
    <div class="line-date">
      <div class="tag">{{ post.tag }}</div>
      <div class="date">
        <img src="../assets/images/icons/calendar.svg" alt="Calendário">
        <span>{{ post.date }}</span>
      </div>
    </div>
    <h2>{{ post.subtitle }}</h2>
    <div class="body" v-html="post.body"/>
  </div>
</template>

<script>

import {SplideSlide, Splide} from "@splidejs/vue-splide";

export default {
  name: "Post",
  components: {
    SplideSlide,
    Splide
  },
  data() {
    return {
      post: {
        title: "Audi e-tron GT. O mais evoluído de sempre",
        images: [
          require("../assets/images/news/post/post-image.png"),
          require("../assets/images/news/post/post-image.png"),
          require("../assets/images/news/post/post-image.png"),
          require("../assets/images/news/post/post-image.png")
        ],
        tag: "Audi e-tron GT",
        date: "16/02/2021",
        subtitle: "Com a versão mais potente chegando aos 600 cv e uma autonomia de 470 cv, o e-tron GT é apresentado como o Audi mais evoluído de sempre.",
        body: "Com quase cinco metros de comprimento o Audi e-tron GT junta à aparência exuberante uma eficácia aerodinâmica que é a melhor de todos os Audi de série e uma mecânica… a condize. Os responsáveis da Audi dizem-nos que o e-tron GT interpreta a visão da mobilidade elétrica segundo a Audi, mostrando, igualmente, as opções estilísticas dos futuros modelos da marca de Ingolstad. E, se assim for, não faltam motivos para estarmos otimistas. O estilo Gran Turismo “funde-se” com as opções estilísticas que habitualmente caracterizam os melhores coupés desportivo, enquanto a reinterpretação da grelha Singleframe remete para a tradição recente da Audi. O habitáculo “puxado” para trás, a reduzida altura,o perfil descendente do tejadilho, a assinatura luminosa na traseira, assim como a grande largura de vias, bem marcada pelos alargamentos dos flancos, são apenas alguns dos pormenores que realçam o posicionamento dinâmico do e-tron GT no que ao estilo diz respeito.",
      }
    }
  }
}
</script>

<style scoped lang="scss">
.component-content {
  h1 {
    margin-top: 130px;
    font-style: normal;
    margin-bottom: 36px;
    font-weight: 600;
    color: #272727;
    font-size: 30px;
    line-height: 33px;

    @media (min-width: 768px) {
      font-size: 35px;
      line-height: 44px;
    }
  }

  h2 {
    margin-top: 63px;
    font-weight: normal;
    font-size: 23px;
    line-height: 32px;
    color: #333333;
  }

  .line-date {
    margin-top: 37px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .tag {
      width: 168px;
      height: 49px;
      background: #333333;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
    }

    .date {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      color: #333333;

      img {
        margin-right: 6px;
      }
    }
  }

  .body {
    margin-top: 22px;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 32px;
    color: #333333;
    margin-bottom: 120px;
  }

}
</style>
