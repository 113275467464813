<template>
  <div class="component-content">
    <div class="container section-1">
      <h1>MyStand, a sua loja de referência</h1>
      <h2>MyStand, Conhecendo, Confia.</h2>
      <div class="row">
        <div class="col-12 col-md-7">
          <p>
            Dinamismo, Competência, Confiança e Responsabilidade, aliados a uma vasta experiência no setor automotivo,
            fazem de nós uma empresa determinada em ser referência no mercado de venda de veículos semi-novos. Temos com
            você o compromisso de Qualidade, Garantia e Melhores Preços.
          </p>
        </div>
      </div>
      <img class="img-fluid img-top" :src="imgTop" alt="Imagem do topo">
      <div class="row mt">
        <div class="col-12 col-md-6">
          <p class="big">A MyStand trabalha diariamente com um único intuito: a total satisfação dos seus
            clientes.</p>
        </div>
        <div class="col-12 col-md-6">
          <p>“A excelência dos automóveis, aliada à qualidade e abrangência dos nossos serviços, bem como a preparação
            rigorosa e precisa das nossos veículos, personificam a MyStand: uma referência em venda
            multimarcas, com centenas de automóveis vendidos e sem quaisquer reclamações.”<br>
            Vendemos e entregamos qualquer veículo em todo o país, sem qualquer custo.
            Contamos com a sua visita!</p>
        </div>
      </div>
    </div>

    <div class="section-2">
      <FsLightbox
          :slide="slide"
          :toggler="toggler"
          :sources="images"
      />
      <div class="horizontal-images">
        <div @click="openLightboxOnSlide(index+1)" v-for="(image, index) in images" :key="index" class="img-box">
          <img :src="`${image}`" alt="Imagem">
        </div>
      </div>
    </div>

    <div class="section-3">
      <div class="container">
        <div class="div-sec-3">
          <div class="div-left">
            <h3>Financiamento</h3>
            <p>
              Soluções por medida para ter o automóvel dos seus sonhos. MyStand – pequenos detalhes, grandes
              diferenças!
              Colocamos ao seu dispor várias soluções de financiamento – Crédito, Leasing e ALD.<br>
              Qualquer que seja a sua opção terá a certeza de obter a solução mais vantajosa, através do nosso
              atendimento personalizado
            </p>

            <h4>Vantagens</h4>
            <ul class="list-unstyled">
              <li><img src="../assets/images/icons/check-green.svg" alt="Check">
                Aprovação imediata
              </li>
              <li><img src="../assets/images/icons/check-green.svg" alt="Check">
                Mensalidades, prazos e taxas fixas, até final do contrato
              </li>
              <li><img src="../assets/images/icons/check-green.svg" alt="Check">
                Possibilidade de financiamento sem entrada inicial, até 120 meses
              </li>
              <li><img src="../assets/images/icons/check-green.svg" alt="Check">
                Pode incluir despesas administrativas, seguros ou acessórios
              </li>
              <li><img src="../assets/images/icons/check-green.svg" alt="Check">
                Amortização sem penalização
              </li>
            </ul>
          </div>
          <div class="div-right">
            <img src="../assets/images/about-image-1.png" alt="Financiamento">
            <h4>Documentos necessários:</h4>
            <ul class="list-unstyled">
              <li><img src="../assets/images/icons/arrow-item-gray.svg" alt="Item">
                Documento de identificação (RG, CHN ou Passaporte)
              </li>
              <li><img src="../assets/images/icons/arrow-item-gray.svg" alt="Item">
                Comprovante de residência
              </li>
              <li><img src="../assets/images/icons/arrow-item-gray.svg" alt="Item">
                Comprovante de renda
              </li>
              <li><img src="../assets/images/icons/arrow-item-gray.svg" alt="Item">
                Contra-cheques (3 últimos)
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="section-4">
      <div class="container">
        <div class="div-sec-4">
          <div class="div-left">
            <img src="../assets/images/about-image-2.png" alt="Financiamento">
          </div>
          <div class="div-right">
            <h3>Serviços de Garantia</h3>
            <p>
              Para a MyStand é um privilégio proporcionar aos nossos clientes os melhores veículos com as
              melhores condições. Como tal lhes oferecemos um Serviço de Garantia baseado em sua satisfação.
            </p>
            <p>
              Contamos com a sua visita para conversarmos sobre os termos e condições do Serviço de Garantia.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let lastKnownScrollPosition = 0;
let move = 0;

document.addEventListener('scroll', function () {
  let scrollPos = window.scrollY;

  let horizontalImages = document.getElementsByClassName('img-box');
  if (scrollPos > (window.outerHeight / 100) * 26) {

    if (scrollPos > lastKnownScrollPosition) {
      move++;
    } else {
      move--;
    }

    lastKnownScrollPosition = scrollPos;

    horizontalImages.forEach(function (element) {
      element.setAttribute("style", "transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -" + (move * 4) + ", 0, 0, 1);")
    })
  } else {
    lastKnownScrollPosition = 0;
    move = 0;
  }
});

import FsLightbox from "fslightbox-vue";

export default {
  name: "About",
  components: {FsLightbox},
  data() {
    return {
      imgTop: window.outerWidth > 768 ? require("@/assets/images/about-top-image.png") : require("@/assets/images/about-top-image-mobile.png"),
      toggler: false,
      slide: 1,
      images: [
        require("@/assets/images/slide-about-1.png"),
        require("@/assets/images/slide-about-2.png"),
        require("@/assets/images/slide-about-3.png"),
        require("@/assets/images/slide-about-1.png"),
      ],
    }
  },
  methods: {
    openLightboxOnSlide: function (number) {
      this.slide = number;
      this.toggler = !this.toggler;
    }
  }
}
</script>

<style scoped lang="scss">
body, html {
  overflow-x: hidden;
}

.component-content {
  h1 {
    margin-top: 130px;
    font-style: normal;
    margin-bottom: 36px;
    font-weight: 600;
    font-size: 30px;
    line-height: 33px;
    color: #272727;

    @media (min-width: 768px) {
      font-size: 35px;
      line-height: 44px;
    }
  }

  .section-1 {

    h2 {
      margin-top: 10px;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      color: #333333;
    }

    p {
      width: 100%;
      margin-top: 28px;
      font-size: 16px;
      line-height: 24px;
      color: #333333;

      &.big {
        font-size: 25px;
        line-height: 32px;
        width: 100%;

        @media (min-width: 1920px) {
          width: 632px;
        }
      }
    }

    .img-top {
      margin-top: 30px;
      width: 100%;

      @media (min-width: 1920px) {
        height: 438px;
      }
    }

    .row {
      &.mt {
        margin-top: 35px;
      }
    }
  }

  .section-2 {
    margin-top: 45px;
    margin-bottom: 45px;

    @media (min-width: 768px) {
      margin-top: 100px;
      margin-bottom: 140px;
    }

    .horizontal-images {
      display: flex;
      overflow: hidden;

      .img-box {
        cursor: pointer;
        transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        height: 150px;
        width: calc(50% - 20px);
        margin-right: 140px;

        img {
          max-height: 100%;
        }

        @media (min-width: 768px) {
          transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
          margin-right: 66px;
          height: 411px;
          width: 827px;
        }
      }
    }
  }

  .section-3 {
    .div-sec-3 {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      @media (min-width: 768px) {
        flex-direction: row;
      }

      .div-left {
        width: 100%;

        @media (min-width: 768px) {
          width: 560px;
        }

        @media (min-width: 1920px) {
          width: 632px;
        }

        h3 {
          font-weight: 600;
          font-size: 30px;
          line-height: 38px;
          color: #272727;
          margin-bottom: 0px;
        }

        h4 {
          margin-top: 51px;
          margin-bottom: 24px;
          font-weight: 600;
          font-size: 24px;
          line-height: 30px;
          color: #272727;
        }

        p {
          margin-top: 23px;
          font-size: 16px;
          line-height: 25px;
          color: #333333;
        }
      }

      .div-right {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        @media (min-width: 768px) {
          width: 560px;
        }

        @media (min-width: 1920px) {
          width: 632px;
        }

        > img {
          filter: drop-shadow(0px 10px 35px rgba(125, 125, 125, 0.25));
        }

        h3 {
          font-weight: 600;
          font-size: 30px;
          line-height: 38px;
          color: #272727;
          margin-bottom: 0px;
        }

        h4 {
          margin-top: 50px;
          margin-bottom: 24px;
          font-weight: 600;
          font-size: 24px;
          line-height: 30px;
          color: #272727;
        }

        ul {
          width: 100%;

          @media (min-width: 768px) {
            columns: 2;
            -webkit-columns: 2;
            -moz-columns: 2;
          }
        }
      }

      ul {
        li {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          font-size: 16px;
          line-height: 20px;
          color: #333333;

          img {
            margin-right: 19px;
          }
        }
      }
    }
  }

  .section-4 {
    margin-top: 40px;
    margin-bottom: 50px;

    @media (min-width: 768px) {
      margin-top: 132px;
      margin-bottom: 137px;
    }

    .div-sec-4 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media (min-width: 768px) {
        flex-direction: row;
      }

      .div-left {
        width: 100%;

        img {
          width: 100%;
          filter: drop-shadow(0px 10px 35px rgba(125, 125, 125, 0.25));
        }

        @media (min-width: 768px) {
          width: 560px;
        }

        @media (min-width: 1920px) {
          width: 640px;
        }
      }

      .div-right {
        width: 100%;
        margin-top: 45px;

        @media (min-width: 768px) {
          margin-top: 0px;
          width: 560px;
        }

        @media (min-width: 1920px) {
          width: 632px;
        }

        h3 {
          font-weight: 600;
          font-size: 30px;
          line-height: 38px;
          color: #272727;
          margin-bottom: 0px;
        }

        p {
          margin-top: 23px;
          font-size: 16px;
          line-height: 25px;
          color: #333333;
        }
      }
    }
  }
}
</style>
